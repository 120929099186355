/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800;900&display=swap");

html {
	color: #52575c;
	font-size: 16px;
	line-height: 1.4;
}

::-moz-selection {
	background: #daf5e4;
	text-shadow: none;
}

::selection {
	background: #daf5e4;
	text-shadow: none;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #fdfdfd;
	margin: 20px 0;
	padding: 0;
	opacity: 0.15;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

a {
	color: #45969b;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
}

a:hover {
	color: #f36e21;
	text-decoration: none;
}

ul {
	margin-bottom: 0;
	padding-left: 15px;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
body {
	font-family: "Lato";
	font-size: 14px;
	line-height: 24px;
	color: #52575c;
	margin: 0;
	background-color: #f6f8fb;
}

.ReactCollapse--collapse {
	transition: height 500ms;
}

.header {
	background-color: #fff;
	position: sticky;
	top: 0;
	width: 100%;
	padding: 20px 0;
	z-index: 100;
}

.header-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-center {
	display: flex;
}

.menu-header {
	display: flex;
	list-style: none;
}

.menu-header li {
	display: flex;
	align-items: center;
	margin: 0 20px;
}

.for-notif {
	position: relative;
	z-index: 1;
}

.notif-status {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	color: #fff;
	font-size: 8px;
	line-height: 15px;
	text-align: center;
	background: #ff6760;
	border: 2px solid #f6f8fb;
	position: absolute;
	top: 5px;
	right: -5px;
	z-index: 3;
}

.nav-top-dropdown .dropdown-menu {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #fff;
	border-radius: 4px;
	filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.12));
}

.nav-top-dropdown .dropdown-menu::before {
	position: absolute;
	display: block;
	content: "";
	top: -6px;
	right: 10px;
	width: 15px;
	height: 15px;
	margin-bottom: 27px;
	z-index: -1;
	border-top: 1px solid #fff;
	border-left: 1px solid #fff;
	background: #fff;
	transform: rotate(45deg);
	transition: all 0.4s ease-in-out;
}

.dropdown-item {
	color: #52575c;
}

.dropdown-item:hover {
	background-color: #f5f5f5;
}

.box-white {
	background: #ffffff;
	box-sizing: border-box;
	display: block;
	border-radius: 8px;
}

.box-white.ws {
	box-shadow: 0px 12px 26px rgba(54, 95, 92, 0.06);
}

.box-white.wb {
	border: 1px solid #e8e8e8;
}

.ttl-24 {
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.002em;
	color: #45969b;
}

.for-btn-show {
	position: absolute;
	right: 5px;
	top: 5px;
}

.link-green {
	color: #45969b;
}

.link-green.active {
	color: #ff6760;
}

.ttl-26 {
	font-weight: 700;
	font-size: 26px;
	line-height: 34px;
	text-align: center;
	letter-spacing: 0.2px;
	color: #52575c;
}

.ttl-20 {
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.2px;
	color: #25282b;
}

.for-logo img,
.logo-for-m img {
	max-height: 40px;
}

.logo-for-m,
.header-left {
	display: none;
}

/* dashboard */
.container-fluid {
	padding-left: 40px;
	padding-right: 40px;
}

.main-wrapper {
	display: flex;
	flex: 1;
	transition: all 200ms ease-in-out;
	height: 100%;
}

.avatar-row {
	display: flex;
	justify-content: center;
}

.for-btm {
	position: absolute;
	top: 20px;
	right: 20px;
}

.sidebar {
	width: 255px;
	height: 100%;
}

.avatar {
	border: 3px solid #e8e8e8;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	overflow: hidden;
	padding: 0;
	background-color: #f1f1f1;
	display: block;
}

.main-wrapper.closed .sidebar,
.main-wrapper.closed .nav-box {
	width: 65px;
}

.page-content {
	width: calc(100% - 255px);
}

.main-wrapper.closed .page-content {
	width: calc(100% - 65px);
}

.nav-box {
	background: #ffffff;
	box-shadow: 4px 0px 16px rgba(54, 95, 92, 0.08);
	position: fixed;
	height: 100%;
	width: 255px;
	left: 0;
	bottom: 0;
	z-index: 101;
	overflow: auto;
}

.logo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 16px;
	border-bottom: 1px solid #e8e8e8;
}

.btn-menu {
	font-size: 16px !important;
	color: #f36e21;
	padding: 10px 0;
}

.btn-menu:hover {
	color: #45969b;
}

.btn-menu::before {
	content: "\e921";
	font-family: "icomoon";
}

.btn-menu.closed::before {
	content: "\e922";
	font-family: "icomoon";
}

.main-wrapper.closed .btn-menu::before {
	content: "\e922";
	font-family: "icomoon";
}

.main-wrapper.closed .logo {
	justify-content: center;
}

.main-wrapper.closed .for-logo {
	display: none;
}

.role-select {
	width: 180px;
}

.btn-profile {
	border: 3px solid #e8e8e8;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	overflow: hidden;
	margin-left: 30px;
	padding: 0;
}

.btn-profile img {
	object-fit: cover;
	object-position: center;
	height: 40px;
}

.dropdown-menu.for-profile {
	background: #ffffff;
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.13);
	border-radius: 8px;
	border: 0;
	padding: 10px 20px;
	min-width: 220px;
}

.dropdown-menu.for-profile.sm {
	min-width: 175px;
}

.dropdown-menu.for-profile li {
	border-bottom: 1px solid #e8e8e8;
}

.dropdown-menu.for-profile li:last-child {
	border-bottom: 0;
}

.dropdown-menu.for-profile li .dropdown-item {
	display: block;
	width: 100%;
	padding: 15px 0;
	font-size: 14px;
	color: #52575c;
	border: 0;
}

.dropdown-menu.for-profile li .dropdown-item:hover {
	color: #f36e21;
	background-color: transparent;
}

.sidebar-content {
	padding: 20px 16px;
	display: flex;
	flex-direction: column;
	height: calc(100% - 100px);
}

.location {
	padding: 16px;
	border-radius: 8px;
	background-color: rgba(69, 150, 155, 0.06);
}

.loc-top {
	display: flex;
	justify-content: space-between;
}

.link-side {
	padding: 15px;
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.1px;
	color: #25282b;
	border-bottom: 1px solid #e8e8e8;
}

.link-loc {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
	color: #45969b;
}

.link-loc .side-desc {
	margin-left: 8px;
}

.link-side span {
	font-size: 18px;
	line-height: 20px;
	color: #a0a4a8;
}

.link-side .side-desc {
	margin-left: 16px;
}

.link-side .side-desc-nested {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.link-side:hover,
.link-side.active,
.link-side:hover span,
.link-side.active span {
	color: #f36e21;
}

.nested-link-side {
	border-bottom: 1px solid #e8e8e8;
}

.nested-link-side ul {
	padding-left: 8px;
}

.nested-link-side ul .link-side {
	border: 0 !important;
}

.main-wrapper.closed .sidebar-content {
	text-align: center;
	padding: 16px;
}

.main-wrapper.closed .link-side {
	border-bottom-color: transparent;
}

.main-wrapper.closed .link-side {
	justify-content: center;
}

.main-wrapper.closed .nested-link-side ul {
	padding-left: 0px;
}

.main-wrapper.closed .side-desc,
.main-wrapper.closed .loc-top,
.main-wrapper.closed .side-menu-sub-title {
	display: none;
}

.main-wrapper.closed .location {
	padding: 16px;
	justify-content: center;
	text-align: center;
}

.main-wrapper.closed .link-loc {
	padding-top: 0;
	justify-content: center;
}

.main-content {
	padding: 47px 36px;
}

.total-pasien {
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.2px;
	color: #25282b;
}

.box-item-d {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.balance-box {
	display: flex;
	align-items: center;
}

.saldo-text {
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.2px;
	color: #25282b;
}

.icon-saldo {
	font-size: 22px;
}

.mh-120 {
	min-height: 120px;
}

.ubah {
	width: 100%;
	height: calc(100vh - 170px);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
}

.setting-label {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #45969b;
	margin-bottom: 6px;
}

.setting-label.required::after {
	content: "*";
	color: #f21a1a;
	padding-left: 5px;
}

.setting-item {
	margin-bottom: 15px;
	position: relative;
}

.setting-item .form-control {
	box-shadow: none;
}

.form-control.otp-form {
	padding: 10px;
	text-align: center;
	font-weight: 800;
	font-size: 56px;
	line-height: 64px;
	color: #45969b;
	border-radius: 0;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 2px solid #dcdcdf;
	background-color: transparent;
}

.form-control.otp-form:focus {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 2px solid #45969b;
	box-shadow: none;
	color: #45969b;
}

.mw-250 {
	min-width: 250px;
}

.scs-text {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	letter-spacing: 0.002em;
	color: #45969b;
}

.table-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 24px 20px;
}

.show-data .form-select {
	width: 120px;
}

.sch-filter {
	width: 70%;
}

.col-lg-1.line {
	width: 3%;
}

hr.dark {
	border-top: 1px solid #272727;
	opacity: 1;
}

.box-grey {
	background: #f6f8fb;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 6px;
}

.user-post {
	display: flex;
	align-items: center;
}

.upi-time {
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #45969b;
}

.post-link {
	display: block;
	color: #52575c;
}

.img-post {
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
}

.img-post img {
	width: 100%;
}

.post-date-info {
	display: flex;
	padding-left: 0;
}

.post-date-info li:first-child {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}

.post-date-info li {
	margin-left: 25px;
}

.comment-info {
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #45969b;
}

.forum-kate-item {
	display: block;
	overflow: hidden;
	background: #f6f8fb;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 6px;
	color: #52575c;
}

.fk-ttl-desc {
	height: 50px;
	margin: 10px 0;
}

.box-border {
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 6px;
}

.forum-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.forum-desc {
	width: 70%;
}

.img-forum {
	background: #f6f8fb;
	border: 2px solid #ffffff;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	overflow: hidden;
}

.img-forum img {
	object-fit: cover;
	object-position: center;
	height: 48px;
}

.link-forum {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 5px;
	display: block;
	letter-spacing: 0.1px;
	color: #45969b;
}

.bx-fd {
	display: flex;
}

.img-user {
	border: 2px solid #ffffff;
	box-shadow: 0px 2px 16px rgba(82, 87, 92, 0.08);
	border-radius: 80px;
	width: 88px;
	height: 88px;
	border-radius: 50%;
	overflow: hidden;
}

.img-user img {
	object-fit: cover;
	object-position: center;
	height: 88px;
}

.img-user.sm {
	border: 2px solid #ffffff;
	box-shadow: none;
	border-radius: 80px;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	overflow: hidden;
}

.img-user.sm img {
	object-fit: cover;
	object-position: center;
	height: 36px;
}

.box-pagi {
	position: relative;
	height: 50px;
}

#front.swiper-button-next,
#front.swiper-button-prev {
	color: #45969b;
}

#front.swiper-button-next {
	right: 60px;
	left: auto;
}

#front.swiper-button-prev {
	right: auto;
	left: 60px;
}

#front .swiper-pagination-bullet-active {
	background: #45969b;
}

.fi-list {
	display: flex;
	padding-left: 0;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #a0a4a8;
}

.fi-list li:first-child {
	list-style: none;
	margin-left: 0;
}

.fi-list li {
	margin-left: 30px;
}

.bx-fk {
	display: inline-block;
	border-radius: 80px;
	padding: 5px 15px;
	font-weight: 400;
	font-size: 13px;
	line-height: 26px;
	letter-spacing: 0.1px;
	color: #ffffff;
}

.bx-fk.green {
	background: #45969b;
}

.bx-fk.purple {
	background: #a15cf9;
}

.bx-fk.orange {
	background: #f36e21;
}

.bx-fk.orange:hover {
	background: #45969b;
	color: #fff;
}

.bx-filter {
	width: 50%;
}

.filter-history {
	width: 75%;
}

.f-filter {
	width: 93%;
}

.ht-filter {
	width: 7%;
}

.jdl-filter {
	width: 66%;
}

.sch-button {
	width: 34%;
}

.box-consult {
	border-radius: 4px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	display: inline-block;
	text-align: center;
	padding: 4px 8px;
	min-width: 80px;
}

.box-consult span {
	vertical-align: bottom;
	font-size: 18px;
}

.box-consult.online {
	color: #3977ef;
	background: rgba(57, 119, 239, 0.05);
}

.box-consult.offline {
	color: #a15cf9;
	background: #f2e8ff;
}

.box-consult.libur {
	color: #52c650;
	background: #dcffe2;
}

.bx-time {
	background: #f6f8fb;
	padding: 4px 8px;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	display: inline-block;
}

.btn-add-schedule {
	position: relative;
	padding-bottom: 10px;
	color: #25282b;
	display: flex;
	justify-content: space-between;
}

.btn-add-schedule::after {
	content: "\e90b";
	font-family: "icomoon";
	color: #f36e21;
}

.btn-add-schedule.open::after {
	content: "\e90e";
	font-family: "icomoon";
}

.inner-jadwal {
	margin-top: 15px;
}

.box-harga {
	display: flex;
	align-items: center;
}

.price-value {
	min-width: 70px;
	margin-right: 20px;
}

.arrow-right {
	margin-right: 20px;
	color: #f36e21;
}

.choose-part {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	background: #fff7e1;
	display: inline-block;
	padding: 10px 25px;
	letter-spacing: 0.1px;
	color: #f36e21;
}

.cp {
	display: flex;
	justify-content: center;
}

.rm-inner {
	height: calc(100vh - 220px);
	overflow-y: auto;
	overflow-x: hidden;
}

.appd-inner {
	height: calc(100vh - 265px);
	overflow-y: auto;
	overflow-x: hidden;
}

.rm-inner::-webkit-scrollbar,
.appd-inner::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
	visibility: hidden;
}

.rm-inner::-webkit-scrollbar-track,
.appd-inner::-webkit-scrollbar-track {
	background: transparent;
}

.rm-inner::-webkit-scrollbar-thumb,
.appd-inner::-webkit-scrollbar-thumb {
	background: transparent;
	border-radius: 10px;
}

.bg-logo {
	position: relative;
	z-index: 3;
	overflow: hidden;
}

.bg-logo::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotateZ(-30deg);
	background-image: url(../../../public/img/logo.svg);
	width: 260px;
	height: 68px;
	opacity: 0.1;
	background-repeat: no-repeat;
	z-index: 1;
}

.appd-tab {
	margin-bottom: 10px;
	justify-content: space-between;
}

.appd-tab .nav-link {
	border: 1px solid #a0a4a8;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 12px 10px;
	min-width: 170px;
	color: #a0a4a8;
	font-weight: 700;
}

.appd-tab .nav-link.active,
.appd-tab .nav-link:hover {
	color: #fff;
	background-color: #45969b;
	border-color: #45969b;
}

.top-item {
	min-height: 120px;
}

.nav-slider {
	position: relative;
	margin-top: 30px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: 5px;
}

/* Login */
.login-wrapper {
	display: flex;
}

.form-main {
	flex: 1 0 45%;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.img-main {
	flex: 1 0 55%;
	height: 100vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.inner-fm {
	max-width: 400px;
	width: 100%;
}

.ttl-28 {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	letter-spacing: 0.002em;
	color: #45969b;
}

.label-fl {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #7b7b7b;
}

.form-login {
	position: relative;
	margin-bottom: 20px;
}

/* change password */
.change-password-wrapper {
	display: flex;
}

.form-change-password {
	position: relative;
	margin-bottom: 20px;
}

.roboto {
	font-family: "Roboto" !important;
}

/* Timeline */
.for-timeline {
	overflow-y: auto;
	overflow-x: hidden;
}

.inner-timeline {
	overflow-y: hidden;
	overflow-x: hidden;
}

.wrapper {
	margin: 0 auto;
	width: 100%;
	height: calc(100vh - 330px);
	overflow-y: auto;
	overflow-x: hidden;
}

.wrapper.ma {
	margin: 0 auto;
	width: 100%;
	height: 300px;
	overflow-y: auto;
	overflow-x: hidden;
}

.wrapper::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
	visibility: hidden;
}

.wrapper::-webkit-scrollbar-track {
	background: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
	background: #f0f0f0;
	border-radius: 10px;
}

.time-line {
	position: relative;
	margin: 0 auto;
	display: flex;
	height: calc(100vh - 330px);
}

.rm-inner .time-line {
	height: auto;
}

.inner-timeline {
	width: 40%;
	position: relative;
}

.inner-timeline::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
	visibility: hidden;
}

.inner-timeline::-webkit-scrollbar-track {
	background: transparent;
}

.inner-timeline::-webkit-scrollbar-thumb {
	background: #f0f0f0;
	border-radius: 10px;
}

.timeline__nav {
	position: relative;
	z-index: 99;
	top: 0;
	transition: top 0.5s ease-out;
}

.timeline__nav.fixed {
	position: relative;
}

.timeline__nav ul {
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.timeline__nav ul li {
	margin-bottom: 10px;
	padding-left: 0;
	list-style-type: none;
	color: #bfc1c3;
	cursor: pointer;
	transition: all 0.3s ease-out;
}

.timeline__nav ul li.active .box-white {
	border: 1px solid #f36e21;
}

.timeline__section {
	width: 60%;
}

.timeline__section.ma {
	width: 90%;
}

.timeline {
	position: relative;
}

.timeline ul {
	padding: 0;
}

.default-line {
	position: absolute;
	left: 50%;
	width: 2px;
	transform: translateX(-50%);
	background: #cacccf;
	height: 100%;
}

.draw-line {
	width: 2px;
	height: 0;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	background: #f36e21;
}

.for-line {
	position: relative;
	height: 100%;
}

.ft-then,
.ft-now {
	font-size: 10px;
	text-align: center;
}

.timeline ul li {
	list-style-type: none;
	position: relative;
	margin-bottom: 15px;
	background: transparent;
}

.timeline ul li.in-view {
	transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out;
}

.timeline ul li.in-view .box-white.wb {
	border: 1px solid #f36e21;
}

.box-line {
	display: flex;
	flex-direction: column;
	width: 50px;
}

.dokter-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.circle-img {
	width: 40px;
	height: 40px;
	border-radius: 50px;
	overflow: hidden;
	background-color: #ebebeb;
}

.circle-img.sm {
	width: 24px;
	height: 24px;
}

.circle-img img {
	object-fit: cover;
	object-position: center;
	height: 40px;
}

.no-info {
	display: flex;
	padding-left: 0;
}

.no-info li {
	margin-bottom: 0 !important;
}

.no-info li::after {
	content: "|";
	color: #e8e8e8;
	padding: 0 5px;
}

.no-info li:last-child:after {
	display: none;
}

.obat-row {
	display: flex;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.obat-col-1 {
	width: 40%;
}

.obat-col-2 {
	width: 25%;
}

.obat-col-3 {
	width: 35%;
}

.appointment-items,
.dm-items {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #e8e8e8;
}

.data-mobile .dm-items:last-child {
	border-bottom-width: 0;
}

.center-pos {
	display: flex;
	justify-content: center;
}

/* table */
.table thead th {
	background-color: rgba(232, 232, 232, 0.2);
	padding: 16px 20px;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.1px;
	color: #52575c;
}

.table.bg-white thead th {
	background-color: #fff;
	padding: 19px 20px;
}

.table tbody td {
	padding: 12px 20px;
	color: #52575c;
	background-color: #fff;
	vertical-align: middle;
}

.table > :not(:last-child) > :last-child > * {
	border-bottom-color: #dbdde0;
}

/* Form */
.form-control,
.form-select {
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	box-shadow: 0px 4px 12px rgba(69, 150, 155, 0.05);
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 14px;
	color: #52575c;
}

.form-control:focus,
.form-select:focus {
	border: 1px solid #45969b;
	box-shadow: 0px 4px 12px rgba(69, 150, 155, 0.05);
	color: #52575c;
}

.form-check-input:checked {
	background-color: #f36e21;
	border-color: #f36e21;
}

.form-check-input input[type="radio"]:checked {
	background-color: #45969b;
	border-color: #45969b;
}

.form-check-input:focus {
	box-shadow: none;
}

.form-control.input-number {
	padding: 0;
	width: 35px;
	border: 0;
	font-weight: 600;
	text-align: center;
	font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.form-control::-moz-placeholder {
	color: #cacccf;
}

.form-control::-webkit-input-placeholder {
	color: #cacccf;
}

.form-control::placeholder {
	color: #cacccf;
}

.login-form .form-control {
	padding-left: 45px;
}

.icon-left {
	position: absolute;
	left: 15px;
	top: 13px;
	z-index: 1;
	font-size: 16px;
	color: #cacccf;
}

.login-form .form-control:focus + .icon-left {
	color: #45969b;
}

.search .form-control {
	background: #ffffff;
	padding: 10px 15px 10px 35px !important;
	box-shadow: none;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 100px !important;
	min-width: 250px;
}

.search-icon {
	position: absolute;
	left: 15px;
	top: 10px;
	color: #cacccf;
}

.icon-right,
.ico-right {
	position: absolute;
	right: 15px;
	top: 13px;
	z-index: 3;
	font-size: 16px;
	color: #cacccf;
}

.ico-right {
	top: 8px;
}

.form-filter .form-control,
.form-filter .form-select,
#ma .form-filter .form-control,
#ma .form-control {
	padding: 10px 15px;
	box-shadow: none;
	border-radius: 4px;
	z-index: 2;
}

.form-filter.ico-left .form-control {
	padding: 10px 15px 10px 40px;
}

.form-filter .form-select,
.setting-item .form-select {
	background-image: none;
	position: relative;
}

.form-filter.for-select::after {
	content: "\e934";
	font-family: "icomoon";
	color: #f36e21;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 3;
}

.form-select.sm {
	padding: 8px 10px;
	box-shadow: none;
	border-radius: 0;
	z-index: 2;
}

.ns {
	box-shadow: none !important;
}

/* Button */
.btn {
	position: relative;
	outline: 0;
	font-size: 14px;
	z-index: 5;
}

.btn:focus {
	box-shadow: none;
	outline: 0;
}

.btn-primary {
	background: #45969b;
}

.btn-primary:hover {
	background: #f36e21;
}

.btn-close-mobile {
	font-size: 20px;
	color: #f36e21;
}

.btn-green {
	background: #45969b;
	border-radius: 80px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	padding: 12px 20px;
}

.btn-green:hover {
	background: #f36e21;
	color: #fff;
}

.btn-orange {
	background: #f36e21;
	border-radius: 80px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	padding: 12px 20px;
}

.btn-orange.sm {
	padding: 5px 10px;
}

.btn-orange:hover {
	background: #45969b;
	color: #fff;
}

.btn-orange-border {
	background: transparent;
	border: 1px solid #f36e21;
	border-radius: 80px;
	color: #f36e21;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	padding: 12px 25px;
}

.btn-orange-border:hover {
	/* background: #45969b; */
	/* border: 1px solid #45969b; */
	background: #f36e21;
	border: 1px solid #f36e21;
	color: #fff;
}

.btn-red {
	background: #f21a1a;
	border-radius: 80px;
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	padding: 12px 20px;
	z-index: auto;
}

.btn-red:hover {
	background: #a90c0c;
	color: #fff;
}

.btn-grey-border {
	background: #fff;
	border: 1px solid #a0a4a8;
	border-radius: 80px;
	color: #a0a4a8;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	padding: 12px 25px;
}

.btn-grey-border:hover {
	background: #a0a4a8;
	border: 1px solid #a0a4a8;
	color: #fff;
}

.btn-show {
	font-size: 18px;
	color: #e8e8e8;
}

.btn-show:hover {
	color: #45969b;
}

.btn-show::before {
	content: "\e917";
	font-family: "icomoon";
}

.btn-show.pass-show::before {
	content: "\e916";
	font-family: "icomoon";
}

.btn-notif {
	color: #cacccf;
	padding: 0;
	font-size: 30px;
	z-index: 1;
}

.btn-notif:hover {
	color: #45969b;
}

.btn-logout {
	margin-left: 40px;
	padding: 7px 30px;
}

.btn-top-nav {
	color: #45969b;
}

.btn-top-nav:hover {
	color: #f36e21;
}

.btn-pdf {
	width: 32px;
	padding: 0;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
	color: #f36e21;
	background: #fff7e1;
}

.btn-print {
	width: 32px;
	padding: 0;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
	color: #45969b;
	background: #e2f7f7;
}

.btn-pdf:hover,
.btn-print:hover {
	color: rgb(51, 51, 51);
}

.btn-close-qr {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	line-height: 15px;
	font-size: 25px;
	text-align: center;
	padding: 0;
	font-weight: bold;
	background-color: #fff;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
	border: 2px solid #ebebeb;
}

.r8 {
	border-radius: 8px;
}

/* Popup */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: all 200ms ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	padding: 15px;
}

.overlay.show {
	visibility: visible;
	opacity: 1;
}

.box-popup {
	background: #ffffff;
	border-radius: 10px;
	padding: 25px;
	position: relative;
}

.box-popup.w430 {
	max-width: 430px;
	width: 100%;
}

.box-popup.w495 {
	max-width: 495px;
	width: 100%;
}

.box-popup.w550 {
	max-width: 550px;
	width: 100%;
}

.box-popup.w650 {
	max-width: 650px;
	width: 100%;
}

.box-popup.w750 {
	max-width: 750px;
	width: 100%;
}

.popup-content.scroll {
	height: 40vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.popup-content.scroll::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.popup-content.scroll::-webkit-scrollbar-track {
	background: #fff;
}

.popup-content.scroll::-webkit-scrollbar-thumb {
	background: #d8d8d8;
	border-radius: 10px;
}

.box-notif {
	width: 100%;
	max-width: 530px;
	background: #ffffff;
	box-shadow: 0px 12px 26px rgba(54, 95, 92, 0.06);
	height: 100%;
	position: fixed;
	right: -530px;
	bottom: 0;
	top: 0;
	left: auto;
	z-index: 112;
	padding: 24px;
	transition: all 200ms ease-in-out;
}

.box-notif.showing {
	right: 0;
}

.notif-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.notif-ttl {
	font-weight: 700;
	font-size: 14px;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0.2px;
	color: #25282b;
}

.notif-item {
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 24px;
	width: 100%;
	text-align: left;
}

.notif-item.not-read {
	border: 1px solid #e2f7f7;
	background: #e2f7f7;
}

.ni-head {
	font-family: "Roboto";
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.notif-content {
	height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.notif-content::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}

.notif-content::-webkit-scrollbar-track {
	background: #fff;
}

.notif-content::-webkit-scrollbar-thumb {
	background: #d8d8d8;
	border-radius: 10px;
}

.loc-items input {
	display: none;
}

.loc-items label {
	display: block;
	background: #ffffff;
	padding: 12px 15px;
	border: 1px solid #cacccf;
	box-sizing: border-box;
	border-radius: 8px;
	color: #52575c;
}

.loc-items input:checked + label {
	border: 1px solid #f36e21;
}

.close-tr {
	position: absolute;
	top: 12px;
	right: 12px;
}

.name-dp {
	font-weight: 700;
	font-size: 19px;
	line-height: 34px;
	letter-spacing: 0.002em;
	color: #45969b;
}

.img-dp {
	background: #f6f8fb;
	width: 60px;
	height: 60px;
	overflow: hidden;
	border: 2px solid #ffffff;
	box-shadow: 0px 2px 16px rgba(82, 87, 92, 0.08);
	border-radius: 50%;
}

.img-dp img {
	object-fit: cover;
	object-position: center;
	height: 60px;
}

/* Pagination */
.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination li:first-child a,
.pagination li:last-child a {
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 24px;
	border-radius: 2px;
	background: #f36e21;
	color: #fff !important;
	display: inline-block;
	font-size: 12px;
}

.pagination li:first-child a:hover,
.pagination li:last-child a:hover {
	background: #cc5a19;
}

.pagination li {
	margin: 0 5px;
}

.pagination li a {
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.1px;
	color: #dbdde0;
}

.pagination li a.active,
.pagination li a:hover {
	color: #f36e21;
}

.pagination li:first-child a.not-active,
.pagination li:last-child a.not-active {
	background: #dbdde0 !important;
	cursor: not-allowed;
}

/* Tooltip */
.tool-tip {
	position: relative;
	z-index: 20;
}

.tool-tip-icon {
	padding: 0;
	display: inline-block;
}

.tool-tip-content {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	left: -50%;
	bottom: 35px;
	font-family: "Poppins";
	font-size: 12px !important;
	line-height: 22px;
	color: #4b554d;
	padding: 3px 5px;
	border-radius: 5px;
	box-shadow: none;
	font-weight: 400 !important;
	background-color: #ffff;
	margin-top: 10px;
	width: 150px;
	text-align: center;
	transform: translateX(-50%);
	z-index: 501;
}

.tool-tip-content.big {
	width: 300px;
	left: 100%;
	bottom: 25px;
	line-height: 22px;
	left: auto;
	text-align: left;
	padding: 15px;
	background-color: #fff;
	font-weight: 400 !important;
	border: 1px solid #f5f5f5;
	right: -100%;
	transform: translateX(0);
	z-index: 102;
}

.tool-tip-content.big.bottom {
	left: 100%;
	bottom: auto;
	right: 100%;
	font-weight: 400 !important;
	transform: translateX(-100%);
	z-index: 102;
}

.tool-tip-icon:hover + .tool-tip-content,
.tool-tip-icon:focus + .tool-tip-content {
	visibility: visible;
	opacity: 1;
}

.tool-tip-content.right {
	right: 50%;
	transform: none;
}

.tool-tip-content.left,
.tool-tip-content.bottom.left {
	left: 50%;
	transform: none;
	font-weight: 400 !important;
}

.tool-tip.floating {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	z-index: 102;
	font-weight: 400 !important;
}

.tool-tip.not-float {
	position: relative;
	display: inline-block;
}

/* Color Option */
.text-dark {
	color: #25282b;
}

.text-default {
	color: #52575c;
}

.text-blue {
	color: #3977ef;
}

.text-green {
	color: #45969b;
}

.text-red {
	color: #f21a1a;
}

.text-orange {
	color: #f36e21;
}

.text-yellow {
	color: #fb9c18;
}

.text-grey {
	color: #a0a4a8;
}

.text-light-grey {
	color: #cacccf;
}

.text-purple {
	color: #a15cf9;
}

.box-status {
	padding: 5px 25px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	display: inline-block;
}

.box-status.terbayar,
.box-status.selesai {
	background: rgba(84, 191, 119, 0.2);
	border-radius: 2px;
	color: #54bf77;
}

.box-status.waiting,
.box-status.serve,
.box-status.diajukan {
	background: rgba(252, 209, 104, 0.2);
	border-radius: 2px;
	color: #fb9c18;
}

.box-status.cancel,
.box-status.not-serve,
.box-status.ditolak {
	background: rgba(242, 26, 26, 0.1);
	border-radius: 2px;
	color: #f21a1a;
}

.box-status.online {
	background: #e2f7f7;
	border-radius: 2px;
	color: #50bcc6;
}

.box-status.onproses {
	background: #dbebff;
	border-radius: 2px;
	color: #297bdb;
}

.box-status.offline {
	background: #f2e8ff;
	border-radius: 2px;
	color: #a15cf9;
}

/* Banner */
.banner-container {
	height: 640px;
	background-size: cover;
	position: relative;
	min-width: calc(100vw - 247px);
}

/* ==========================================================================
   Responsive Design
   =========================================================================== */
@media (min-width: 1701px) {
}

@media (max-width: 1700px) {
}

@media (max-width: 1500px) {
}

@media (max-width: 1400px) {
	.appd-tab .nav-link {
		min-width: 150px;
	}

	.sliderTop .swiper-slide {
		width: 34%;
	}

	.main-wrapper.closed .sliderTop .swiper-slide {
		width: 32.2%;
	}
}

@media (max-width: 1300px) {
}

@media (max-width: 1024px) {
	.page-content,
	.main-wrapper.closed .page-content {
		width: calc(100% - 65px);
		margin-left: 65px;
	}

	.sidebar {
		width: 255px;
		position: fixed;
		z-index: 115;
		transition: all 100ms ease-in-out;
	}

	.header {
		padding-left: 65px;
	}

	.main-content {
		padding: 40px 40px 20px 105px;
	}

	.sch-filter {
		width: 80%;
	}

	.jdl-filter {
		width: 55%;
	}

	.sch-button {
		width: 43%;
		margin-top: 10px;
	}

	.inner-fm {
		max-width: 300px;
		width: 100%;
	}

	.appd-tab .nav-link {
		min-width: 120px;
	}
}

@media (max-width: 991px) {
	.menu-header li {
		margin: 0 10px;
	}

	.sch-filter {
		width: 100%;
	}

	.sch-filter.wd {
		width: 80%;
	}

	.col-lg-1.line {
		display: none;
	}

	.img-post {
		margin-bottom: 15px;
	}

	.fi-list,
	.forum-item {
		flex-wrap: wrap;
	}

	.forum-desc {
		width: 100%;
	}

	.forum-cate-box {
		margin-left: 50px;
		padding: 10px 0;
	}

	.jdl-filter {
		width: 100%;
	}

	.sch-button {
		width: 100%;
		margin-top: 10px;
	}

	.form-main {
		flex: 1 0 100%;
		height: 100vh;
	}

	.img-main {
		display: none;
	}

	.inner-fm {
		max-width: 400px;
		width: 100%;
	}

	.role-select {
		width: 120px;
	}

	.btn-orange-border {
		padding: 12px 15px;
	}

	.rm-inner {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.appd-tab {
		margin-bottom: 10px;
		justify-content: flex-start;
	}

	.appd-tab .nav-link {
		margin-right: 5px;
	}

	.tt-items {
		width: 100%;
		display: block;
	}

	.sliderTop .swiper-slide {
		width: 55%;
	}

	.main-wrapper.closed .sliderTop .swiper-slide {
		width: 55%;
	}
}

@media (max-width: 767px) {
	.notif-content {
		height: 70vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.menu-header,
	.data-desktop {
		display: none;
	}

	.container-fluid {
		padding-left: 20px;
		padding-right: 20px;
	}

	.role-select {
		width: 120px;
	}

	.logo {
		display: none;
	}

	.avatar-row {
		padding-top: 30px;
	}

	.main-wrapper.closed .sidebar,
	.main-wrapper.closed .nav-box {
		width: 255px;
		left: -100%;
		position: fixed;
		z-index: 115;
		transition: all 100ms ease-in-out;
	}

	.main-wrapper .sidebar,
	.main-wrapper .nav-box {
		width: 255px;
		left: -100%;
		position: fixed;
		z-index: 115;
		transition: all 100ms ease-in-out;
	}

	.main-wrapper.closed.show .sidebar,
	.main-wrapper.closed.show .nav-box,
	.main-wrapper.show .sidebar,
	.main-wrapper.show .nav-box {
		left: 0;
		z-index: 116;
	}

	.main-wrapper.closed.show .sidebar::after,
	.main-wrapper.show .sidebar::after {
		content: "";
		position: fixed;
		z-index: -1;
		background-color: rgba(0, 0, 0, 0.16);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.main-wrapper.closed .side-desc {
		display: inline-block;
		text-align: left;
	}

	.header {
		padding-left: 0;
	}

	.main-content {
		padding: 20px 20px;
	}

	#desk-item {
		display: none;
	}

	.logo-for-m,
	.header-left {
		display: block;
	}

	.ubah {
		width: 100%;
		height: 100%;
		display: block;
		padding: 0;
	}

	.mw-250 {
		min-width: 200px;
	}

	.search {
		width: 100%;
	}

	.search .form-control {
		min-width: 100%;
	}

	.table-bottom {
		flex-wrap: wrap;
	}

	.show-data {
		width: 100%;
	}

	.for-paging {
		width: 100%;
		margin-top: 15px;
	}

	.sch-filter.wd,
	.export,
	.btn-export {
		width: 100%;
	}

	.bx-fd {
		flex-wrap: wrap;
	}

	.forum-cate-box {
		margin-left: 0px;
		padding: 10px 0;
	}

	.fi-list li {
		margin-left: 20px;
	}

	#ff.p24 {
		padding: 15px;
	}

	.box-harga {
		flex-wrap: wrap;
	}

	.inner-fm {
		max-width: 300px;
		width: 100%;
	}

	.bx-filter {
		width: 100%;
	}

	.inner-timeline {
		display: none;
	}

	.box-line {
		display: none;
		flex-direction: column;
		width: 25px;
		margin-right: 5px;
	}

	.timeline__section {
		width: 100%;
	}

	.appd-tab .nav-link {
		min-width: 100px;
	}

	.timeline__section.ma {
		width: 100%;
	}

	.tindakan-content {
		height: 40vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.user-post-info {
		width: 70%;
	}
}

@media (min-width: 1025px) {
}

@media (min-width: 768px) {
	.menu-mobile,
	.avatar-row,
	.for-btm,
	.data-mobile {
		display: none;
	}
}

@media (max-width: 991px) and (orientation: landscape) {
	.sidebar-content {
		height: 75vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.ubah {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}
}

@media (max-width: 700px) and (orientation: landscape) {
}

@media (min-width: 1200px) {
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 66.6666666667%;
	}

	.col-xxl-3 {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
}

@media (min-width: 1601px) {
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xxl-1 {
		flex: 0 0 auto;
		width: 4.3333333333%;
	}
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
.no-scroll {
	overflow: hidden;
	height: 100vh;
}

.no-border {
	border: 0 !important;
}

.mb10 {
	margin-bottom: 10px;
}
.mb20 {
	margin-bottom: 20px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb40 {
	margin-bottom: 40px;
}

.mb50 {
	margin-bottom: 50px;
}

.text-right {
	text-align: right;
}

.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal !important;
	font-weight: 400;
}

.text-500 {
	font-weight: 500;
}

.text-600 {
	font-weight: 600;
}

.ft-18 {
	font-size: 18px !important;
}

.ft-16 {
	font-size: 16px;
}

.ft-14 {
	font-size: 14px;
}

.ft-13 {
	font-size: 13px !important;
}

.ft-12 {
	font-size: 12px !important;
}

.ft-6 {
	font-size: 6px;
	line-height: normal;
	vertical-align: middle;
}

.p15 {
	padding: 15px;
}

.p20 {
	padding: 20px;
}

.p24 {
	padding: 24px;
}

.p30 {
	padding: 30px;
}

.p40 {
	padding: 40px;
}

.hidden,
[hidden] {
	display: none !important;
}

.sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	/* 1 */
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	white-space: inherit;
	width: auto;
}

.invisible {
	visibility: hidden;
}

.clearfix::before,
.clearfix::after {
	content: " ";
	display: table;
}

.clearfix::after {
	clear: both;
}

/* ==========================================================================
   Font Icon
   ========================================================================== */
@font-face {
	font-family: "icomoon";
	src: url("../../../public/fonts/icomoon.eot?lz1giy");
	src: url("../../../public/fonts/icomoon.eot?lz1giy#iefix") format("embedded-opentype"),
		url("../../../public/fonts/icomoon.ttf?lz1giy") format("truetype"),
		url("../../../public/fonts/icomoon.woff?lz1giy") format("woff"),
		url("../../../public/fonts/icomoon.svg?lz1giy#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-alert-triangle:before {
	content: "\e900";
}
.icon-check-circle:before {
	content: "\e901";
}
.icon-check-square:before {
	content: "\e902";
}
.icon-upload-cloud:before {
	content: "\e903";
}
.icon-ico-appointment:before {
	content: "\e904";
}
.icon-ico-arrow-right:before {
	content: "\e905";
}
.icon-ico-back:before {
	content: "\e906";
}
.icon-ico-bullet:before {
	content: "\e907";
}
.icon-ico-calendar:before {
	content: "\e908";
}
.icon-ico-card:before {
	content: "\e909";
}
.icon-ico-check:before {
	content: "\e90a";
}
.icon-ico-chev-down:before {
	content: "\e90b";
}
.icon-ico-chev-left:before {
	content: "\e90c";
}
.icon-ico-chev-right:before {
	content: "\e90d";
}
.icon-ico-chev-up:before {
	content: "\e90e";
}
.icon-ico-close:before {
	content: "\e90f";
}
.icon-ico-comment:before {
	content: "\e910";
}
.icon-ico-dashboard:before {
	content: "\e911";
}
.icon-ico-date:before {
	content: "\e912";
}
.icon-ico-delete:before {
	content: "\e913";
}
.icon-ico-edit:before {
	content: "\e914";
}
.icon-ico-email:before {
	content: "\e915";
}
.icon-ico-eye-close:before {
	content: "\e916";
}
.icon-ico-eye-open:before {
	content: "\e917";
}
.icon-ico-facebook:before {
	content: "\e918";
}
.icon-ico-gmail:before {
	content: "\e919";
}
.icon-ico-h-marker:before {
	content: "\e91a";
}
.icon-ico-hospital:before {
	content: "\e91b";
}
.icon-ico-idr:before {
	content: "\e91c";
}
.icon-ico-instagram:before {
	content: "\e91d";
}
.icon-ico-key:before {
	content: "\e91e";
}
.icon-ico-link:before {
	content: "\e91f";
}
.icon-ico-logout:before {
	content: "\e920";
}
.icon-ico-menu-desk:before {
	content: "\e921";
}
.icon-ico-menu-mobile:before {
	content: "\e922";
}
.icon-ico-minus:before {
	content: "\e923";
}
.icon-ico-next:before {
	content: "\e924";
}
.icon-ico-notif:before {
	content: "\e925";
}
.icon-ico-pdf:before {
	content: "\e926";
}
.icon-ico-pencil:before {
	content: "\e927";
}
.icon-ico-pin:before {
	content: "\e928";
}
.icon-ico-plus:before {
	content: "\e929";
}
.icon-ico-print:before {
	content: "\e92a";
}
.icon-ico-reply:before {
	content: "\e92b";
}
.icon-ico-report:before {
	content: "\e92c";
}
.icon-ico-result:before {
	content: "\e92d";
}
.icon-ico-schedule:before {
	content: "\e92e";
}
.icon-ico-search:before {
	content: "\e92f";
}
.icon-ico-setting:before {
	content: "\e930";
}
.icon-ico-stetoskop:before {
	content: "\e931";
}
.icon-ico-stopwatch:before {
	content: "\e932";
}
.icon-ico-time:before {
	content: "\e933";
}
.icon-ico-triangle-down:before {
	content: "\e934";
}
.icon-ico-triangle-left:before {
	content: "\e935";
}
.icon-ico-triangle-right:before {
	content: "\e936";
}
.icon-ico-triangle-up:before {
	content: "\e937";
}
.icon-ico-user:before {
	content: "\e938";
}
.icon-ico-wa:before {
	content: "\e939";
}
.icon-ico-wallet:before {
	content: "\e93a";
}
.icon-ico-zoom:before {
	content: "\e93b";
}
.icon-users:before {
	content: "\e972";
}
.icon-aid-kit:before {
	content: "\e998";
}
.icon-briefcase:before {
	content: "\e9ae";
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
	*,
	*::before,
	*::after {
		background: #fff !important;
		color: #000 !important;
		/* Black prints faster */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href^="#"]::after,
	a[href^="javascript:"]::after {
		content: "";
	}

	pre {
		white-space: pre-wrap !important;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}

/* ==========================================================================
   Custom Timepicker styles
   ========================================================================== */

.rc-time-picker-input {
	display: block;
	width: 100%;
	padding: 14px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .rc-time-picker {
	display: inline-block;
	position: relative;
	box-sizing: border-box;
} */
.rc-time-picker * {
	box-sizing: border-box;
}
.rc-time-picker-clear {
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 3px;
	margin: 0;
}
.rc-time-picker-clear-icon:after {
	content: "x";
	font-size: 12px;
	font-style: normal;
	color: #aaa;
	display: inline-block;
	line-height: 1;
	height: 20px;
	width: 20px;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
}
.rc-time-picker-clear-icon:hover:after {
	color: #666;
}
/* .rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
} */
.rc-time-picker-input[disabled] {
	color: #ccc;
	background: #f7f7f7;
	cursor: not-allowed;
}
.rc-time-picker-panel {
	z-index: 1070;
	width: 170px;
	position: absolute;
	box-sizing: border-box;
}
.rc-time-picker-panel * {
	box-sizing: border-box;
}
.rc-time-picker-panel-inner {
	display: inline-block;
	position: relative;
	outline: none;
	list-style: none;
	font-size: 12px;
	text-align: left;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 5px #ccc;
	background-clip: padding-box;
	border: 1px solid #ccc;
	line-height: 1.5;
}
.rc-time-picker-panel-narrow {
	max-width: 113px;
}
.rc-time-picker-panel-input {
	margin: 0;
	padding: 0;
	width: 100%;
	cursor: auto;
	line-height: 1.5;
	outline: 0;
	border: 1px solid transparent;
}
.rc-time-picker-panel-input-wrap {
	box-sizing: border-box;
	position: relative;
	padding: 6px;
	border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
	border-color: red;
}
.rc-time-picker-panel-select {
	float: left;
	font-size: 12px;
	border: 1px solid #e9e9e9;
	border-width: 0 1px;
	margin-left: -1px;
	box-sizing: border-box;
	width: 56px;
	max-height: 144px;
	overflow-y: auto;
	position: relative;
}
.rc-time-picker-panel-select-active {
	overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
	border-left: 0;
	margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
	border-right: 0;
}
.rc-time-picker-panel-select ul {
	list-style: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
}
.rc-time-picker-panel-select li {
	list-style: none;
	margin: 0;
	padding: 0 0 0 16px;
	width: 100%;
	height: 24px;
	line-height: 24px;
	text-align: left;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.rc-time-picker-panel-select li:hover {
	background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
	background: #f7f7f7;
	font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
	color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}

.choose-body-skeleton {
	flex: 1;
	display: flex;
	margin-top: 8px;
}

.background-white {
	background-color: #fff;
}

table.table > tbody > tr > td[role="cell"] {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

.misc-page section:not(:last-of-type) {
	margin-bottom: 1.5rem;
}

.misc-page section .title {
	font-weight: 500;
	font-size: 2rem;
	line-height: 2.4rem;
	color: #25282b;
	margin-bottom: 1.5rem;
}

.misc-page section .title-2 {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #25282b;
	margin-bottom: 1.5rem;
}

.misc-page section .sub-title {
	font-weight: 700;
	font-size: 1.125rem;
	line-height: 1.5rem;
	color: #25282b;
	margin-bottom: 1.5rem;
}

.misc-page section p,
.misc-page section li {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.5rem;
	color: #25282b;
	margin-bottom: 1rem;
}

.misc-page section p:last-child {
	margin-bottom: 0rem;
}

.misc-page section p:has(+ div.sub-title) {
	margin-bottom: 1.5rem;
}

.article-post {
	margin-bottom: 24px;
}

.article-post-content {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.article-post-profile-picture {
	width: 36px;
	height: 36px;
	border-radius: 50%;
}

.article-post-thumbnail {
	aspect-ratio: 16 / 9;
	object-fit: cover;
	border-radius: 8px;
}

.article-post-infinite-scroll {
	overflow-x: hidden !important;
}

.side-menu-sub-title {
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
	color: #25282b;
}

.profile-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 1.5rem;
}

.main-wrapper.closed .profile-container {
	display: none;
}

.profile-container img {
	width: 64px;
	height: 64px;
	object-fit: cover;
	object-position: center;
	border-radius: 100%;
	margin-right: 16px;
}

.profile-container .profile-text div:nth-child(1) {
	color: #25282b;
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 2px;
	width: 135px;
}

.profile-container .profile-text div:nth-child(2),
.profile-container .profile-text a {
	color: #a0a4a8;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	margin-bottom: 3px;
}

.profile-container .profile-text a {
	color: #f36e21;
	cursor: pointer;
}

.content-title {
	font-weight: 700;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.2px;
	color: #25282b;
}

.content-btn {
	padding: 12px 24px;
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
}

.post-counter {
	background-color: #f4f4f4;
	border: 1px solid #e8e8e8;
	font-size: 12px;
	line-height: 18px;
	padding: 8px;
	border-radius: 8px;
	margin-left: 16px;
}

.post-search-box {
	padding: 0;
	margin-bottom: 0 !important;
	background-color: transparent;
	box-shadow: none !important;
}

.post-search-box .search {
	margin-bottom: 0 !important;
}

.post-filter-box {
	padding: 10px 35px 10px 15px;
}

.post-table .data-desktop {
	padding: 32px 32px 0 32px;
}

.post-table .table-bottom {
	padding: 24px 32px 32px 32px;
	border-top: 1px solid #e8e8e8;
}

.post-table.no-data .table-responsive {
	overflow: hidden;
}

.post-table .table-bottom ul.pagination li a {
	font-size: 14px;
}

.post-table .table-bottom ul.pagination li:first-child a,
.post-table .table-bottom ul.pagination li:last-child a {
	border-radius: 6px;
}

.post-table table {
	margin-bottom: 0;
	border-color: #e8e8e8;
}

.post-table thead {
	display: none;
}

.post-table .table tbody td {
	padding: 24px 0;
}

.post-table .table tbody tr:first-child td {
	padding-top: 0;
}

.post-table .table tbody tr:last-child {
	border-bottom-color: transparent;
}

.post-edit-btn {
	font-size: 16px;
	color: #25282b;
	padding: 12px 20px;
	border: 1px solid #e8e8e8;
	border-radius: 8px;
	display: inline-block;
	cursor: pointer;
}

.post-edit-btn:hover {
	border-color: #f36e21;
}

.data-mobile .post-edit-btn {
	margin-top: 16px;
	padding: 0px 12px;
	padding-top: 3px;
	padding-bottom: 1px;
	border-radius: 4px;
}

.post-items {
	display: flex;
}

.post-items img {
	width: 105px;
	height: 80px;
	object-fit: cover;
	object-position: center;
	border-radius: 6px;
	margin-right: 24px;
}

.post-items .post-item-title {
	font-weight: 700;
	line-height: 24px;
	font-size: 16px;
	color: #25282b;
	margin-bottom: 6px;
	white-space: normal;
}

.post-items .post-item-subtitle {
	font-weight: 400;
	line-height: 20px;
	font-size: 14px;
	color: #a0a4a8;
}

/* Post Editor Style */
.editor-wrapper .sidebar {
	width: 0px;
}
.editor-wrapper .form-control {
	box-shadow: none;
}

.editor-wrapper .sidebar .nav-box {
	width: 0px;
	box-shadow: none;
	background-color: #fff;
}

.editor-wrapper .sidebar.setting-sidebar .nav-box {
	border-left: 1px solid #e8e8e8;
	left: auto;
	right: 0;
}

.editor-wrapper .sidebar.toolbar-sidebar .nav-box {
	border-right: 1px solid #e8e8e8;
	right: auto;
	left: 0;
}

.editor-wrapper.setting-opened .sidebar.setting-sidebar,
.editor-wrapper.setting-opened .sidebar.setting-sidebar .nav-box,
.editor-wrapper.toolbar-opened .sidebar.toolbar-sidebar,
.editor-wrapper.toolbar-opened .sidebar.toolbar-sidebar .nav-box {
	width: 377px;
}

.editor-wrapper .sidebar .nav-box .sidebar-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: none;
	padding: 40px 24px 32px;
}

.editor-wrapper .sidebar .nav-box .sidebar-header-title {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #25282b;
}

.editor-wrapper .sidebar .nav-box .form {
	border-bottom-width: 0;
	justify-content: space-between;
	padding: 0px 24px 42px;
}

.editor-wrapper .sidebar .nav-box .form label {
	color: #25282b;
}

.editor-wrapper .sidebar .nav-box .form .rc-time-picker {
	position: relative;
}

.editor-wrapper .sidebar.setting-sidebar .nav-box .form .rc-time-picker input {
	font-size: 14px;
	line-height: 20px;
}

.editor-wrapper .sidebar.setting-sidebar .nav-box .form .rc-time-picker .icon-ico-time {
	top: 20px !important;
	bottom: auto !important;
	right: 14px !important;
	width: auto !important;
}

.editor-wrapper .sidebar.setting-sidebar .nav-box .form .form-information-message {
	font-size: 14px;
	color: #a0a4a8;
	line-height: 20px;
	margin: 6px 0 0;
}

.editor-wrapper .sidebar.toolbar-sidebar .toolbar-category-title {
	color: #a0a4a8;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 8px;
}

.editor-wrapper .sidebar.toolbar-sidebar .toolbar-item-icon {
	width: 38px;
	height: 38px;
	background-color: #e2f7f7;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.editor-wrapper .sidebar.toolbar-sidebar .toolbar-item-title {
	font-size: 14px;
	line-height: 24px;
	color: #25282b;
}

.editor-wrapper .sidebar.toolbar-sidebar .toolbar-item-description {
	font-size: 12px;
	line-height: 18px;
	color: #a0a4a8;
}

.editor-wrapper .sidebar.setting-sidebar .nav-box .form .form-error-item-message {
	margin: 0;
	margin-top: 6px;
}

.editor-wrapper .page-content {
	width: 100%;
	background-color: #fff;
	position: relative;
}

.editor-wrapper.setting-opened .page-content,
.editor-wrapper.toolbar-opened .page-content,
.editor-wrapper.setting-opened .editor-header,
.editor-wrapper.toolbar-opened .editor-header,
.editor-wrapper.setting-opened .alert,
.editor-wrapper.toolbar-opened .alert {
	width: calc(100% - 377px);
}

.editor-wrapper.setting-opened.toolbar-opened .page-content,
.editor-wrapper.setting-opened.toolbar-opened .editor-header,
.editor-wrapper.setting-opened.toolbar-opened .alert {
	width: calc(100% - 754px);
}

.editor-wrapper .editor-header {
	display: flex;
	justify-content: space-between;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	position: fixed;
	padding: 40px 24px;
	align-items: center;
}

.editor-wrapper .alert {
	margin-bottom: 0;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 99;
}

.editor-wrapper.setting-opened .editor-header,
.editor-wrapper.setting-opened .alert {
	right: 377px;
}

.editor-wrapper.toolbar-opened .editor-header,
.editor-wrapper.toolbar-opened .alert {
	left: 377px;
}

.editor-wrapper .editor-header .editor-header-left,
.editor-wrapper .editor-header .editor-header-right {
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 0.25rem;
}

.editor-wrapper .btn-editor {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	padding: 12px 16px;
}

.editor-wrapper .btn-editor.btn-editor-toolbar-trigger {
	padding: 0px 0px 2px;
	border: 1px solid #e8e8e8;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	color: #a0a4a8;
}

.editor-wrapper .btn-editor:not(.btn-orange):hover {
	background-color: #ebeef0;
}

.editor-wrapper .btn-editor-orange {
	color: #f36e21;
	font-weight: 500;
}

.editor-wrapper .btn-editor-orange:hover {
	background-color: rgba(243, 110, 33, 0.08) !important;
}

.editor-wrapper .btn-editor-red {
	color: #f21a1a;
	font-weight: 500;
}

.editor-wrapper .btn-editor-red:hover {
	background-color: rgba(242, 26, 26, 0.08) !important;
}

.editor-wrapper .editor-main {
	display: flex;
	justify-content: center;
	padding: 170px 92px;
}

.editor-wrapper .editor-main .editor-content {
	width: 622px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.editor-wrapper .editor-main .editor-content .editor-banner {
	position: relative;
}

.editor-wrapper .editor-main .editor-content .editor-banner-btn-remover {
	position: absolute;
	top: 8px;
	right: 8px;
	font-size: 14px;
	padding: 4px 8px;
	background-color: #25282b;
	color: #fff;
	display: none;
}

.editor-wrapper .editor-main .editor-content .editor-banner:hover .editor-banner-btn-remover {
	display: block;
}

.editor-wrapper .editor-main .editor-content .editor-banner-btn-remover:hover {
	background-color: #f21a1a;
}

.editor-wrapper .editor-main .editor-content .editor-banner-btn {
	cursor: pointer;
}

.editor-wrapper .editor-main .editor-content .editor-banner-btn-icon {
	padding: 8px 10px;
	border: 1px solid #e8e8e8;
	border-radius: 8px;
	background-color: #fff;
}

.editor-wrapper .editor-main .editor-content .editor-banner-btn .editor-banner-btn-text {
	font-size: 14px;
	line-height: 20px;
	color: #a0a4a8;
	padding: 10px 0;
}

.editor-wrapper .editor-main .editor-content .editor-banner-title-field {
	padding: 0;
	font-size: 28px;
	line-height: 48px;
	font-weight: 700;
	border: none;
	box-shadow: none;
	color: #25282b;
}

.editor-wrapper .editor-main .editor-content .remirror-editor:focus-visible,
.remirror-editor .alert-box strong,
.remirror-editor .alert-box p {
	outline: none;
}

.editor-field-input {
	position: relative;
}

.editor-field-input:focus {
	outline: none;
}

.rc-time-picker-input-error .rc-time-picker-input {
	border-color: #f21a1a;
}

.btn-floating-format {
	background-color: #ffffff;
	line-height: 12px;
}

.btn-floating-format img {
	width: 12px;
}

.btn-floating-format[active="true"],
.btn-floating-format:hover {
	background-color: #e8e8e8;
}

.post-toolbar {
	display: flex;
	align-items: center;
}

.box-popup.box-popup-unplash {
	max-width: 100vw;
	width: 100%;
	margin: 24px;
	padding: 0;
	overflow: hidden;
}

.box-popup.box-popup-unplash .popup-content.scroll {
	height: calc(100vh - 250px);
	padding: 0 68px;
	display: flex;
	gap: 24px;
}

.box-popup.box-popup-unplash .unplash-container {
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.box-popup.box-popup-unplash .unplash-container .unplash-photo {
	width: 100%;
	display: block;
	position: relative;
}

.box-popup.box-popup-unplash .unplash-container .unplash-photo img {
	position: absolute;
	width: 100%;
}

.box-popup.box-popup-unplash .unplash-container .unplash-photo .unplash-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), transparent 40%, transparent 60%, rgba(0, 0, 0, 0.3));
	transition: all 0.15s ease-in-out;
	opacity: 1;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 20px;
	opacity: 0;
}

.box-popup.box-popup-unplash .unplash-container .unplash-photo:hover .unplash-overlay {
	opacity: 1;
}

.editor-wrapper .editor-mobile-action {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px 16px;
	z-index: 100;
}

.editor-wrapper .editor-mobile-action > div {
	background-color: #fff;
	border-radius: 0.25rem;
}

.editor-wrapper .editor-main .embed-field {
	width: 622px;
	z-index: 99;
}

.remirror-editor.ProseMirror {
	overflow-y: visible !important;
}

.remirror-editor {
	color: #25282b;
}

.remirror-editor .styled-quote {
	margin: 0;
	padding: 1rem;
	background-color: #f9f9f9;
	border-left: 4px solid #ccc;
	font-style: italic;
	color: #333;
	line-height: 1.4;
}

.remirror-editor > p {
	margin: 0;
	padding-bottom: 1rem;
}

.remirror-editor h2:not(:first-child) {
	padding-top: 10px;
}

.remirror-editor > h2,
.remirror-editor > h3 {
	margin-bottom: 0;
	padding-bottom: 0.5rem;
}

.remirror-editor .responsive-image,
.remirror-editor .responsive-video {
	width: 100%;
	height: auto;
}

.remirror-editor .card {
	width: 100%;
	padding: 1rem;
	position: relative;
	background: white;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
}

.remirror-editor .card-title {
	color: #25282b;
	font-size: 16px;
	font-family: system-ui, -apple-system, sans-serif;
	font-weight: 600;
	line-height: 1.6;
	margin: 0 0 8px 0;
}

.remirror-editor .card-content {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
	margin-top: 0.5rem;
}

.remirror-editor .card-description {
	color: #25282b;
	font-size: 12px;
	line-height: 1.5;
	margin: 0;
}

.remirror-editor .card-secondary-description {
	color: #a0a4a8;
	font-size: 12px;
	line-height: 1.5;
	margin: 0;
}

.remirror-editor .card-download {
	position: absolute;
	right: 1rem;
	top: 1rem;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.remirror-editor .alert-box {
	width: 100%;
	padding: 1rem;
	box-sizing: border-box;
	background-color: #f1f8ff;
	border: 1px solid #ccc;
}

.remirror-editor .alert-box p {
	margin: 0;
}

.remirror-editor .custom-details {
	padding: 1rem 0;
}

.remirror-editor .details-content {
	padding: 1rem;
}

.remirror-editor .video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.remirror-editor .video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}

.remirror-editor .embed-wrapper[data-target="twitter"] p {
	color: white;
}

.remirror-editor .embed-wrapper[data-target="twitter"] .react-tweet-theme {
	margin-top: 0;
}

.remirror-editor .image-placeholder,
.remirror-editor .video-placeholder,
.remirror-editor .file-placeholder,
.remirror-editor .embed-placeholder {
	background-color: #f6f8fb;
	height: 334px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
}

.remirror-editor .embed-placeholder {
	background-color: transparent;
	height: 50px;
	outline: none !important;
}

.remirror-editor .embed-placeholder[data-placeholder-html="true"] {
	height: 252px;
}

.remirror-editor .file-placeholder {
	height: 108px;
}

.remirror-editor .image-placeholder img {
	width: 36px;
	height: 36px;
	margin-bottom: 18px;
}

.remirror-editor .video-placeholder img {
	width: 45px;
	height: 25px;
	margin-bottom: 18px;
}

.remirror-editor .image-placeholder span,
.remirror-editor .video-placeholder span,
.remirror-editor .file-placeholder span {
	color: #a0a4a8;
	font-size: 14px;
	line-height: 20px;
}

.remirror-editor :last-child {
	margin-bottom: 0 !important;
}

.editor-container .editor-html-btn {
	display: none;
}

.editor-container .remirror-editor .ProseMirror-selectednode {
	outline: none !important;
	z-index: 1;
	position: relative;
}

.editor-container.editor-container-focused .remirror-editor .ProseMirror-selectednode {
	outline: 2px solid #45969b !important;
}

.editor-container.editor-container-focused .remirror-editor .twitter-embed-container.ProseMirror-selectednode {
	outline: none !important;
}

.editor-container.editor-container-focused
	.remirror-editor
	.twitter-embed-container.ProseMirror-selectednode
	.react-tweet-theme {
	outline: 2px solid #45969b !important;
}

.editor-container.editor-container-focused .remirror-editor .html-embed-container.ProseMirror-selectednode {
	min-height: 54px;
}

.editor-container.editor-container-focused .editor-html-btn {
	display: block;
}

@media (max-width: 1024px) {
	.main-wrapper.editor-wrapper {
		padding: 0;
	}
	.main-content {
		padding: 20px 0;
	}
	.post-toolbar {
		flex-direction: column;
		align-items: flex-start;
	}
	.post-toolbar-filter,
	.post-search-box {
		width: 100%;
	}
	.btn-create-post {
		padding: 10px;
		font-size: 16px;
	}
	.editor-wrapper .editor-header {
		padding: 30px 16px;
	}
	.editor-wrapper .editor-main {
		padding: 120px 40px;
	}

	.editor-wrapper.setting-opened .page-content,
	.editor-wrapper.toolbar-opened .page-content,
	.editor-wrapper.setting-opened .editor-header,
	.editor-wrapper.toolbar-opened .editor-header,
	.editor-wrapper.setting-opened .alert,
	.editor-wrapper.toolbar-opened .alert {
		width: 100%;
	}
	.editor-wrapper.toolbar-opened .editor-header,
	.editor-wrapper.toolbar-opened .alert {
		left: 0;
	}
	.editor-wrapper .sidebar.setting-sidebar,
	.editor-wrapper .sidebar.toolbar-sidebar {
		transition: none;
	}
	.editor-wrapper .sidebar.setting-sidebar .nav-box,
	.editor-wrapper .sidebar.toolbar-sidebar .nav-box {
		transition: all 100ms ease-in-out;
	}
	.editor-wrapper.setting-opened .sidebar.setting-sidebar,
	.editor-wrapper.toolbar-opened .sidebar.toolbar-sidebar {
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		left: auto;
	}
	.editor-wrapper .sidebar .nav-box .sidebar-header {
		padding: 30px 24px 32px;
	}

	.box-popup.box-popup-unplash .popup-content.scroll {
		padding: 0 24px;
	}
}
