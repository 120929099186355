.PhoneInput {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(69, 150, 155, 0.05);
    border-radius: 4px;
    padding: 14px;
    
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PhoneInput .PhoneInputInput {
    font-size: 14px;
    line-height: 20px;
    border: none;
    color: #52575c;
    padding: 0 0.5rem;
}

.PhoneInput .PhoneInputInput::placeholder {
    color: #cacccf;
}

.PhoneInput:focus-within {
    border: 1px solid #45969b;
    box-shadow: 0px 4px 12px rgba(69, 150, 155, 0.05);
}

.PhoneInput .PhoneInputInput:focus {
    border: none;
    outline: 0;
}

.PhoneInput svg {
    margin: 0;
}