/* Custom Helper */
.mr5 {
	margin-right: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mr20 {
	margin-right: 20px;
}

.mr30 {
	margin-right: 30px;
}

.mr40 {
	margin-right: 40px;
}

.mr50 {
	margin-right: 50px;
}

.ft-20 {
	font-size: 20px !important;
}

.ft-22 {
	font-size: 22px !important;
}

.ft-55 {
	font-size: 55px !important;
}

.icon-large {
	font-size: 3rem;
}

.spinner {
	margin: 0px auto 0;
	width: 70px;
	text-align: center;
}

.spinner-colorful > div {
	background: linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
	background: -webkit-linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
}

.spinner > div {
	width: 11px;
	height: 11px;
	background-color: #fff;
	margin-right: 5px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.btn-disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}

.form-control-error,
.form-select-error {
	border: 1px solid #c75050;
	box-shadow: 0px 4px 12px rgba(176, 59, 59, 0.05);
	color: #52575c;
	animation: fadeInAnimation 0.5s;
}

.form-error-item-message {
	color: #c75050;
	font-size: 12px;
	margin-top: 2px;
	margin-left: 5px;
	animation: fadeInAnimation 0.5s;
}

.dual-listbox {
	padding: 0px !important;
}

.dual-listbox-filter {
	margin-bottom: 20px;
	padding-bottom: 7px;
	padding-top: 7px;
}

.duallist-box-select-item {
	padding: 10px;
	border: 1px solid #e8e8e8;
	cursor: pointer;
}

.duallist-box-select-item:focus,
.duallist-box-select-item:hover,
.duallist-box-select-item:checked,
.duallist-box-select-item.selected {
	background-color: #f0f0f0;
}

.btn-dual-listbox-move {
	padding: 7px;
	margin: 7px 0px;
	border: 1px solid #a0a0a0;
	border-radius: 4px;
	background: transparent;
	color: #52575c;
	font-size: 20px;
	cursor: pointer;
}

.btn-dual-listbox-move > .disabled {
	color: #dbdbdb;
	cursor: not-allowed;
}

@keyframes fadeInAnimation {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Width */

.w-20 {
	width: 20%;
}

.w-45 {
	width: 45%;
}

.w-50 {
	width: 50%;
}

.w-100 {
	width: 100%;
}

/* Upload file */
.for-img-change {
	border: 2px solid #ffffff;
	box-shadow: none;
	border-radius: 80px;
	width: 108px;
	height: 108px;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0px 2px 16px rgba(82, 87, 92, 0.08);
}

.for-img-change img {
	object-fit: cover;
	object-position: center;
	height: 108px;
}

.for-img-change div {
	object-fit: cover;
	object-position: center;
	height: 108px;
}

.for-info-change {
	margin-left: 20px;
}

.upload-img-patient input {
	display: none;
}

.change-img {
	display: flex;
	align-items: center;
}

.btn-upload-img-patient {
	background: #f9f9f9;
	padding: 5px 15px;
	cursor: pointer;
	margin: 5px 0;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 1px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.002em;
	color: #a0a4a8;
}

.image-upload {
	width: 100%;
	height: 150px;
	border: 1px dashed #ddd;
	border-radius: 5px;
	margin-bottom: 20px;
	position: relative;
	text-align: center;
	background: #f8f8f9;
	color: #666;
	overflow: hidden;
}

.item-wrapper form img {
	margin-bottom: 20px;
	width: auto;
	height: auto;
	max-height: 200px;
	width: auto;
	border-radius: 5px;
	overflow: hidden;
}

.image-upload img {
	height: 100% !important;
	width: auto !important;
	border-radius: 0px;
	margin: 0 auto;
}

.image-upload i {
	font-size: 6em;
	color: #ccc;
}

.image-upload input {
	cursor: pointer;
	opacity: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
}

.item-wrapper input {
	height: 43px;
	line-height: 43px;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 20px;
}

/* For Custom Toast */

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbar {
	visibility: hidden;
	padding: 0.8rem 1.2rem !important;
	border-radius: 0;
}

.snackbar.show {
	visibility: visible;
	animation: fadein 0.2s;
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		bottom: 0;
		opacity: 0;
	}
}

#items-searchable-dropdown::-webkit-scrollbar {
	width: 4px;
}

#items-searchable-dropdown::-webkit-scrollbar-track {
	background: #fff;
}

#items-searchable-dropdown::-webkit-scrollbar-thumb {
	background-color: rgb(167, 167, 167);
	border-radius: 20px;
}

.btn-preview-file {
	border: 1px solid rgb(160, 160, 160);
	font-size: 13px;
	margin-right: 8px;
	padding: 5px 10px 5px 10px !important;
	color: rgb(150, 150, 150);
}

.btn-checkin {
	background: #f9f9f9;
	padding: 5px 10px;
	cursor: pointer;
	margin: 5px 0;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 1px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.002em;
	color: #a0a4a8;
}

.search-hospital-container {
	min-width: 600px;
}

.search-hospital-input {
	background: #ffffff;
	padding: 10px 15px 10px 15px !important;
	box-shadow: none;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	min-width: 250px;
}

.text-elipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* ==========================================================================
   Responsive Design
   =========================================================================== */
@media (min-width: 1701px) {
}

@media (max-width: 1700px) {
}

@media (max-width: 1500px) {
}

@media (max-width: 1400px) {
}

@media (max-width: 1300px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 991px) {
	.btn-add-container {
		width: 100% !important;
	}
}

@media (max-width: 767px) {
	.btn-add-container {
		width: 100% !important;
	}

	.search-hospital-input {
		margin-bottom: 10px;
	}
}

@media (min-width: 1025px) {
}

@media (min-width: 768px) {
}

@media (max-width: 450px) {
	.text-table-small {
		font-size: 10px;
	}

	.text-table-medium {
		font-size: 13px;
	}

	.search-hospital-input {
		margin-bottom: 10px;
	}

	.search-hospital-container {
		min-width: 230px;
	}

	.btn-add-container {
		width: 100% !important;
	}

	.m-desktop-only {
		margin: unset;
	}
}

.text-disabled {
	color: rgb(213 213 213);
}

.text-disabled:hover {
	color: rgb(213 213 213);
	cursor: not-allowed !important;
}

.box-view-result {
	height: calc(100vh - 160px);
}

.inner-pick-day-embed {
	min-height: 440px;
	max-height: 460px;
	overflow: auto;
}

.date-choose {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	width: 73px;
	height: 73px;
	border-radius: 50%;
	text-align: center;
	border: 1.5px solid #45969b;
	box-sizing: border-box;
}

.dc-inner {
	display: block;
	text-align: center;
	font-weight: 800;
	font-size: 13px;
	text-align: center;
	line-height: 17px;
	letter-spacing: 0.1px;
	color: #52575c;
	position: relative;
}

.dc-inner span {
	display: block;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.1px;
	color: #a0a4a8;
}

.date-choose.active {
	color: #fff !important;
	background: #45969b;
}

.date-choose.active span,
.date-choose.active .dc-inner {
	color: #fff !important;
}

.date-choose:disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.week.swiper-button-next::after,
.week.swiper-button-prev::after {
	display: none;
}

.week.swiper-button-next,
.week.swiper-button-prev {
	position: absolute;
	top: 0;
	width: 50%;
	height: auto;
	margin-top: 0;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 800;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #45969b;
	right: 0;
}

.week.swiper-button-prev {
	justify-content: flex-start;
	left: 0;
}

.nav-week {
	position: relative;
	height: 50px;
}

.week.swiper-button-next.swiper-button-disabled,
.week.swiper-button-prev.swiper-button-disabled {
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.sliderJadwal .swiper-slide {
	display: flex;
	justify-content: center;
}

.sliderJadwal .swiper-wrapper {
	display: flex;
}

.inner-pick-day-embed {
	min-height: 440px;
	max-height: 460px;
	overflow: auto;
}

.indicator {
	margin-top: 20px;
	position: relative;
	display: block;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #cacccf;
	z-index: 3;
}

.indicator span {
	display: inline-block;
	padding: 0 20px;
	background-color: #fff;
}

.indicator::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #e8e8e8;
	z-index: -1;
	display: block;
	left: 0;
	top: 50%;
}

.tab-info > .time-choose {
	display: none;
}

.tab-info > .active {
	display: block;
	animation: fade-in 0.2s;
}

.text-gray {
	color: #c0c0c0 !important;
}

.inner-tc {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding: 30px 10px 10px 10px;
}

.tc-items input {
	display: none;
}

.tc-items input + label {
	background: #fafafa;
	border: 1.5px solid #45969b;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px 40px;
	font-weight: 700;
	margin: 7px;
	position: relative;
	color: #a0a4a8;
	cursor: pointer;
}

.tc-items input:disabled + label,
.c-items input:disabled + label {
	border: 1.5px solid #a0a4a8;
	cursor: pointer;
}

.tc-items label:hover + .na-info,
.c-items label:hover + .na-info {
	opacity: 1;
	visibility: visible;
}

.tc-items input + label.available {
	background: #ffffff;
	color: #45969b;
}

.tc-items input:checked + label {
	color: #45969b;
	background: #e2f7f7;
}

.tc-items input:checked + label::after {
	content: "\2713";
	font-family: "icomoon";
	position: absolute;
	top: 0px;
	right: 5px;
}

.gmt {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.img-globe {
	margin-right: 8px;
}

.gmt .ft-12 {
	line-height: 25px;
}

.na-info {
	margin-bottom: 15px;
	background: #f6f8fb;
	padding: 15px;
	position: absolute;
	border: 1px solid #f6f8fb;
	box-sizing: border-box;
	z-index: 10;
	bottom: 100%;
	left: -30px;
	right: -30px;
	filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
	border-radius: 5px;
	opacity: 0;
	visibility: hidden;
	transition: all 200ms ease-in-out;
}

.inner-tc {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding: 30px 10px 10px 10px;
}

.gmt {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.img-globe {
	margin-right: 8px;
}

.gmt .ft-12 {
	line-height: 25px;
}

.tc-items,
.c-items {
	position: relative;
}

.na-info {
	margin-bottom: 15px;
	background: #f6f8fb;
	padding: 15px;
	position: absolute;
	border: 1px solid #f6f8fb;
	box-sizing: border-box;
	z-index: 10;
	bottom: 100%;
	left: -30px;
	right: -30px;
	filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.06));
	border-radius: 5px;
	opacity: 0;
	visibility: hidden;
	transition: all 200ms ease-in-out;
}

.c-items .na-info {
	top: 100%;
	bottom: auto;
	left: -30px;
	right: -30px;
	z-index: 30;
	margin-top: 15px;
}

.na-info::after {
	position: absolute;
	display: block;
	content: "";
	top: auto;
	bottom: -6px;
	right: 45%;
	width: 20px;
	height: 20px;
	margin-bottom: 0;
	z-index: 1;
	border-top: 1px solid #f6f8fb;
	border-left: 1px solid #f6f8fb;
	background: #f6f8fb;
	transform: rotate(45deg);
}

.c-items .na-info::after {
	position: absolute;
	display: block;
	content: "";
	top: -6px;
	bottom: auto;
	right: 45%;
	width: 20px;
	height: 20px;
	margin-bottom: 0;
	z-index: 1;
	border-top: 1px solid #f6f8fb;
	border-left: 1px solid #f6f8fb;
	background: #f6f8fb;
	transform: rotate(45deg);
}

.tc-items input {
	display: none;
}

.tc-items input + label {
	background: #fafafa;
	border: 1.5px solid #45969b;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px 40px;
	font-weight: 700;
	margin: 7px;
	position: relative;
	color: #a0a4a8;
	cursor: pointer;
}

.tc-items input:disabled + label,
.c-items input:disabled + label {
	border: 1.5px solid #a0a4a8;
	cursor: pointer;
}

.tc-items label:hover + .na-info,
.c-items label:hover + .na-info {
	opacity: 1;
	visibility: visible;
}

.tc-items input + label.available {
	background: #ffffff;
	color: #45969b;
}

.tc-items input:checked + label {
	color: #45969b;
	background: #e2f7f7;
}

.tc-items input:checked + label::after {
	content: "✅";
	font-family: "icomoon";
	position: absolute;
	top: 0px;
	right: 5px;
}

.week.swiper-button-next::after,
.week.swiper-button-prev::after {
	display: none;
}

.week.swiper-button-next,
.week.swiper-button-prev {
	position: absolute;
	top: 0;
	width: 50%;
	height: auto;
	margin-top: 0;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: 800;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1px;
	color: #45969b;
	right: 0;
}

.week.swiper-button-prev {
	justify-content: flex-start;
	left: 0;
}

.nav-week {
	position: relative;
	height: 50px;
}

.week.swiper-button-next.swiper-button-disabled,
.week.swiper-button-prev.swiper-button-disabled {
	opacity: 0.5;
	cursor: auto;
	pointer-events: none;
}

.sliderJadwal .swiper-slide {
	display: flex;
	justify-content: center;
}

.tab-info {
	min-height: 150px;
}

.ns {
	box-shadow: none;
}

.box-other {
	display: none;
}

.box-other.on {
	display: block;
}

.fill-inner {
	height: 510px;
	overflow-y: auto;
	overflow-x: hidden;
}

.fill-inner::-webkit-scrollbar {
	width: 4px;
}

.fill-inner::-webkit-scrollbar-thumb {
	background: #d4d0d0;
	border-radius: 9px;
}

.for-who .form-check-input:checked {
	background-color: #f36e21;
	border-color: #f36e21;
}

.choose-day.not-show,
.fill-id.not-show {
	transition: all 200ms ease-in-out;
	opacity: 0;
	visibility: hidden;
	display: none;
}

.choose-day,
.fill-id {
	transition: all 200ms ease-in-out;
	opacity: 1;
	visibility: visible;
	animation: fade-in 0.2s;
}

.box-share-link {
	background-color: #fff;
	max-width: 430px;
	width: 100%;
	padding: 20px;
	box-shadow: 0px 12px 26px rgba(54, 95, 92, 0.06);
	border-radius: 8px;
}

.ls {
	border: 1px solid #dbdde0;
	box-sizing: border-box;
	border-radius: 8px;
	width: 100%;
	height: 88px;
	display: block;
	line-height: 88px;
	font-size: 30px;
	text-align: center;
	margin-bottom: 5px;
}

.i-box {
	max-width: 600px;
	width: 100%;
	padding: 20px;
	background-color: #fff;
	box-shadow: 0px 12px 26px rgba(54, 95, 92, 0.06);
	border-radius: 8px;
}

.with-border {
	border: 5px solid rgba(232, 232, 232, 0.2);
}

.collapse-box {
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.25s ease-out;
}

.collapse-box.show {
	max-height: 500px;
	transition: max-height 0.35s ease-in;
}

.cursor-pointer {
	cursor: pointer;
}

.box-show {
	position: relative;
	min-height: 200px;
	transition: all 150ms ease-in-out;
	overflow: hidden;
}

.for-self,
.for-other {
	position: absolute;
	transition: all 180ms ease-in-out;
}

.for-self {
	left: 0;
}

.for-self.hide {
	left: -100%;
}

.for-other {
	right: -100%;
}

.for-other.show {
	right: 0;
}

.for-before-arrow,
.for-after-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 5%;
	transition: all 100ms ease-in-out;
	visibility: hidden;
	opacity: 0;
}

.for-after-arrow {
	right: 5%;
	left: auto;
}

.for-before-arrow.show,
.for-after-arrow.show {
	visibility: visible;
	opacity: 1;
}

.btn-before {
	font-size: 20px;
	padding: 0;
	color: #45969b;
}

#tab-choose li:first-child {
	margin-right: -1px;
}

#tab-choose .nav-link.left,
#tab-choose .nav-link.right {
	background-color: #fff;
	padding: 15px 25px;
	border: 1px solid #dee2e6;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	z-index: 3;
	position: relative;
}

#tab-choose .nav-link.active {
	background: linear-gradient(260.84deg, #45969b 0%, #5aafb5 96.32%);
	color: #fff;
}

#tab-choose .nav-link.left {
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right-color: #fff;
}

#tab-choose .nav-link.right {
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left-color: #fff;
}

.separator {
	margin-top: -23px;
	margin-bottom: 40px;
	z-index: -1;
}

/* Color Option */
.text-dark {
	color: #25282b;
}

/* Color Option */
.text-dark {
	color: #25282b;
}

.text-default {
	color: #52575c;
}

.text-blue {
	color: #3977ef;
}

.text-green {
	color: #45969b;
}

.text-red {
	color: #f21a1a;
}

.text-orange {
	color: #f36e21;
}

.text-grey {
	color: #a0a4a8;
}

.text-light-grey {
	color: #cacccf;
}

.text-purple {
	color: #a15cf9;
}

.box-status {
	padding: 5px 25px;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	display: inline-block;
}

.box-status.terbayar {
	background: rgba(84, 191, 119, 0.2);
	border-radius: 2px;
	color: #54bf77;
}

.box-status.waiting {
	background: rgba(252, 209, 104, 0.2);
	border-radius: 2px;
	color: #fcd168;
}

.box-status.cancel {
	background: rgba(242, 26, 26, 0.1);
	border-radius: 2px;
	color: #f21a1a;
}

/* ==========================================================================
   Custom Timepicker styles
   ========================================================================== */

.rc-time-picker-input {
	display: block;
	width: 100%;
	padding: 14px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .rc-time-picker {
		display: inline-block;
		position: relative;
		box-sizing: border-box;
	} */
.rc-time-picker * {
	box-sizing: border-box;
}

.rc-time-picker-clear {
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 3px;
	margin: 0;
}

.rc-time-picker-clear-icon:after {
	content: "x";
	font-size: 12px;
	font-style: normal;
	color: #aaa;
	display: inline-block;
	line-height: 1;
	height: 20px;
	width: 20px;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.rc-time-picker-clear-icon:hover:after {
	color: #666;
}

/* .rc-time-picker-input {
		width: 100%;
		position: relative;
		display: inline-block;
		padding: 4px 7px;
		height: 28px;
		cursor: text;
		font-size: 12px;
		line-height: 1.5;
		color: #666;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	} */
.rc-time-picker-input[disabled] {
	color: #ccc;
	background: #f7f7f7;
	cursor: not-allowed;
}

.rc-time-picker-panel {
	z-index: 999999999;
	width: 170px;
	position: absolute;
	box-sizing: border-box;
}

.rc-time-picker-panel * {
	box-sizing: border-box;
}

.rc-time-picker-panel-inner {
	display: inline-block;
	position: relative;
	outline: none;
	list-style: none;
	font-size: 12px;
	text-align: left;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 1px 5px #ccc;
	background-clip: padding-box;
	border: 1px solid #ccc;
	line-height: 1.5;
}

.rc-time-picker-panel-narrow {
	max-width: 113px;
}

.rc-time-picker-panel-input {
	margin: 0;
	padding: 0;
	width: 100%;
	cursor: auto;
	line-height: 1.5;
	outline: 0;
	border: 1px solid transparent;
}

.rc-time-picker-panel-input-wrap {
	box-sizing: border-box;
	position: relative;
	padding: 6px;
	border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
	border-color: red;
}

.rc-time-picker-panel-select {
	float: left;
	font-size: 12px;
	border: 1px solid #e9e9e9;
	border-width: 0 1px;
	margin-left: -1px;
	box-sizing: border-box;
	width: 56px;
	max-height: 144px;
	overflow-y: auto;
	position: relative;
}

.rc-time-picker-panel-select-active {
	overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
	border-left: 0;
	margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
	border-right: 0;
}

.rc-time-picker-panel-select ul {
	list-style: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
}

.rc-time-picker-panel-select li {
	list-style: none;
	margin: 0;
	padding: 0 0 0 16px;
	width: 100%;
	height: 24px;
	line-height: 24px;
	text-align: left;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rc-time-picker-panel-select li:hover {
	background: #edfaff;
}

.btn-upload-img-patient {
	background: #f9f9f9;
	padding: 5px 15px;
	cursor: pointer;
	margin: 5px 0;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 1px;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.002em;
	color: #a0a4a8;
}

li.rc-time-picker-panel-select-option-selected {
	background: #f7f7f7;
	font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
	color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}

/* ==========================================================================
   Responsive Design
   =========================================================================== */
@media (min-width: 1701px) {
	.setting-content {
		width: calc(100% - 17vw);
	}
}

@media (max-width: 1700px) {
	.setting-content {
		width: calc(100% - 11vw);
	}
}

@media (max-width: 1500px) {
	.setting-content {
		width: calc(100% - 7vw);
	}
}

@media (max-width: 1400px) {
	.setting-content {
		width: calc(100% - 4vw);
	}
}

@media (max-width: 1300px) {
	.setting-content {
		width: calc(100% - 1vw);
	}
}

@media (max-width: 1024px) {
	.landing {
		height: 100%;
		flex-wrap: wrap;
		padding: 50px 0;
	}

	.menu-for-header {
		margin-left: 35px;
	}

	.right-content {
		width: 350px;
		position: fixed;
		right: -350px;
		top: 110px;
		transition: all 200ms ease-in-out;
		z-index: 50;
		box-shadow: none;
	}

	.right-content.showing {
		right: 0;
	}

	.right-content.showing .box-white {
		box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.08);
		border-top-left-radius: 0 !important;
	}

	.center-content {
		width: 100%;
	}

	.container-fluid {
		padding-right: 20px;
		padding-left: 20px;
	}

	.main-wrapper {
		padding: 30px 20px;
	}

	.for-btn-jadwal {
		width: 50px;
		height: 50px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		background-color: #fff;
		position: absolute;
		z-index: 1;
		left: -50px;
		top: 0;
		box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.08);
	}

	.btn-jadwal {
		font-size: 25px;
		color: #45969b !important;
	}

	.btn-jadwal::before {
		font-family: "icomoon";
		content: "\e92d";
	}

	.right-content.showing .btn-jadwal::before {
		font-family: "icomoon";
		content: "\e90b";
	}

	.forum-desc {
		width: 75%;
	}

	.fi-list {
		flex-wrap: wrap;
	}

	.link-for-forum {
		width: 80%;
	}

	.search .form-control {
		min-width: 100px;
	}

	.bx-filter {
		width: 70%;
	}

	.filter-history,
	.f-filter,
	.ht-filter {
		width: 100%;
	}

	#r-f {
		width: 20%;
		text-align: center;
	}

	.r-col {
		width: 80%;
	}

	.inner-as,
	.fill-inner {
		height: 60vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.inner-cons {
		height: 20vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.as-footer {
		padding-top: 40px;
	}

	.main-nav {
		position: sticky;
		top: 100px;
	}

	.c-items .na-info {
		left: 3px;
		right: -140px;
	}

	.c-items .na-info::after {
		right: 75%;
	}
}

@media (max-width: 991px) {
	.landing-heading {
		font-size: 25px;
		line-height: 31px;
	}

	.sliderDokter .p30 {
		padding: 20px;
	}

	#front.swiper-button-next,
	#mobile-p.swiper-button-next {
		right: 10px;
		left: auto;
	}

	#front.swiper-button-prev,
	#mobile-p.swiper-button-prev {
		right: auto;
		left: 10px;
	}

	.menu-for-header {
		margin-left: 0;
	}

	.img-post {
		margin-bottom: 15px;
	}

	.forum-item {
		flex-wrap: wrap;
	}

	.forum-desc {
		width: 100%;
	}

	.forum-cate-box {
		width: 70%;
		padding-left: 20%;
		margin-top: 15px;
	}

	.link-for-forum {
		width: 75%;
	}

	.bx-filter,
	.filter-history,
	.f-filter {
		width: 100%;
	}

	.inner-as {
		height: auto;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.inner-cons {
		height: auto;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.as-footer {
		padding-top: 10px;
	}

	.line-c {
		margin: 0 25px;
	}

	#r-f {
		width: 10%;
	}

	.r-col {
		width: 90%;
	}

	.c-items input + label {
		padding: 10px 10px;
		white-space: unset;
	}

	.c-items input + label span::before {
		content: "\e917";
		font-family: "icomoon";
		display: block;
		text-align: center;
		font-size: 16px;
		color: #3977ef;
	}

	.for-self.hide {
		left: -150%;
	}

	.for-other {
		right: -150%;
	}

	.for-before-arrow,
	.for-after-arrow {
		left: 0;
	}

	.for-after-arrow {
		right: 0;
		left: auto;
	}
}

@media (max-width: 767px) {
	.container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}

	.header-left {
		display: block;
	}

	.for-logout {
		display: none;
	}

	.btn-logout {
		margin-left: 0;
		padding: 7px 25px;
	}

	.main-nav {
		width: 250px;
		height: 100%;
		max-height: 100%;
		position: fixed;
		left: -250px;
		top: 0;
		bottom: 0;
		transition: all 200ms ease-in-out;
		z-index: 200;
	}

	.main-nav .box-white {
		height: 100%;
		max-height: 100%;
		width: 100%;
		padding-top: 50px;
		border-radius: 0 !important;
	}

	.main-nav.show {
		left: 0;
	}

	.main-nav.show::before {
		content: "";
		position: fixed;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.main-nav.show .box-white {
		box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.08);
	}

	.close-menu {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	.close-popup {
		position: absolute;
		top: 45px;
		right: 20px;
	}

	.btn-close-menu,
	.btn-close-popup {
		font-size: 22px;
	}

	.for-btn-nav {
		width: 50px;
		height: 50px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		background-color: #fff;
		position: absolute;
		z-index: 1;
		right: -50px;
		display: flex;
		align-items: center;
		top: 0;
		box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.08);
	}

	.btn-nav {
		font-size: 20px;
		line-height: 20px;
		color: #f36e21 !important;
	}

	.btn-nav::before {
		font-family: "icomoon";
		content: "\e91e";
	}

	.main-nav.show .btn-nav::before {
		font-family: "icomoon";
		content: "\e90b";
	}

	.main-content {
		width: 100%;
	}

	.right-content {
		display: none;
	}

	#appointment.right-content {
		display: block;
	}

	#appointment.right-content {
		width: 100%;
		position: relative;
		right: 0;
		top: 30px;
		z-index: 50;
		box-shadow: none;
	}

	#appointment.for-btn-jadwal {
		display: none;
	}

	.landing {
		background-image: none;
	}

	.landing-content .box-white {
		margin-bottom: 30px;
	}

	.footer-inner {
		justify-content: center;
	}

	.for-logo-bottom {
		margin-bottom: 20px;
	}

	.footer-address {
		text-align: center;
	}

	.dokter-bg {
		background-size: auto 400px;
	}

	.dokter-heading {
		font-size: 46px;
		line-height: 50px;
	}

	.menu-for-header {
		display: none;
	}

	.forum-cate-box {
		width: 90%;
		padding-left: 20%;
		margin-top: 15px;
	}

	.fi-list li {
		margin-left: 30px;
		margin-bottom: 5px;
	}

	#setting-tab .nav-link {
		padding: 10px 10px;
	}

	.for-info-change {
		margin-left: 20px;
		width: 50%;
	}

	.janji-content,
	.popup-content {
		height: 40vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.notif-content {
		height: 70vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.sidebar-content {
		/* height: 30vh; */
		overflow-y: auto;
		overflow-x: hidden;
	}

	.btn-orange-border {
		padding: 12px 5px;
	}

	.di-info {
		width: 100%;
	}

	.add-schedule,
	.inner-as-app {
		width: 100%;
	}

	.type-1 {
		width: 30%;
	}

	.type-2 {
		width: 65%;
	}

	.app-inner {
		display: flex;
		flex-wrap: wrap;
		overflow-x: hidden;
	}

	.label-tele {
		padding: 20px 5px !important;
	}

	.for-line {
		background-color: transparent;
		width: 5%;
		margin-bottom: 10px;
	}

	.line-c {
		height: 70%;
		width: 100%;
		background-color: transparent;
		margin: 0;
		position: relative;
	}

	.line-c::after {
		content: "";
		width: 1px;
		position: absolute;
		height: 100%;
		background-color: #e8e8e8;
		left: 50%;
		transform: translateX(-50%);
	}

	.fill-inner {
		height: calc(100vh - 21vh);
		min-height: 0;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 45px 24px 0 24px;
	}

	.fill-inner-app {
		padding: 30px 24px 0 24px;
	}

	.inner-as {
		height: calc(100vh - 16vh);
		overflow-y: auto;
		overflow-x: hidden;
	}

	.info-dokter {
		padding: 35px 24px 0 24px;
	}

	.btn-close-popup {
		background-color: #fff;
		width: 35px;
		height: 35px;
		border-radius: 3px;
	}

	.as-footer {
		padding: 24px;
	}

	.di-info.no-app {
		display: none;
	}

	.c-items .na-info {
		left: 3px;
		right: -200px;
	}

	.c-items .na-info::after {
		right: 85%;
	}

	.app-box {
		max-width: 100%;
		width: 100%;
		height: 100vh;
		z-index: 120;
		flex-wrap: wrap;
		padding: 0;
		border-radius: 0;
	}

	.hide-m {
		display: none;
	}

	.di-info .shadow {
		box-shadow: none !important;
	}

	.inner-tc {
		padding: 30px 0 20px 0;
	}

	.tc-items input + label {
		padding: 10px 20px;
	}

	.sliderDokter {
		display: none;
	}

	.janji-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.janji-item {
		width: 49% !important;
		text-align: left !important;
	}

	.sliderDokterM .swiper-slide {
		width: 35%;
	}

	.dokter-title {
		height: auto;
		margin-bottom: 10px;
	}

	.date-m {
		width: 50%;
		text-align: center;
	}

	#file-view {
		visibility: hidden;
		position: fixed;
		display: flex;
		justify-items: center;
		/* align-items: center; */
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 120;
		background-color: rgba(0, 0, 0, 0.2);
		padding: 28px;
		opacity: 0;
		transition: all 200ms ease-in-out;
	}

	#file-view.show {
		visibility: visible;
		opacity: 1;
	}

	.close-fv {
		position: absolute;
		top: -10px;
		right: -10px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: #fff;
		text-align: center;
		line-height: 30px;
	}

	#for-d,
	.data-desktop {
		display: none;
	}

	.box-waktu {
		width: 84.5%;
	}

	.table tbody td {
		padding: 12px 15px;
	}

	.box-status.rt {
		padding: 5px 15px;
	}

	.c-items input + label {
		padding: 15px 10px;
		font-size: 12px;
	}

	.ht-filter::after,
	.r-filter::after {
		content: "\e907";
		font-family: "icomoon";
		margin-left: 10px;
	}

	.ht-filter.collapsed::after,
	.r-filter.collapsed::after {
		content: "\e90a";
		font-family: "icomoon";
		margin-left: 10px;
	}

	#r-f {
		width: 100%;
		text-align: left;
	}

	.r-col {
		width: 100%;
	}

	.flex-m {
		display: flex;
		align-items: center;
	}

	.add-schedule {
		height: calc(100vh - 205px);
	}

	.di-info #di {
		padding: 30px 20px 0 20px;
	}

	#di .mb-4 {
		margin-bottom: 10px !important;
	}

	.di-m,
	.di-m .dokter-name {
		text-align: left !important;
	}

	.nav-week {
		margin: 0 24px;
		height: 35px;
	}

	.consult {
		padding: 0 24px;
	}

	.j-center {
		justify-content: center;
	}

	.box-ma {
		height: calc(100vh - 150px);
		overflow-y: auto;
		overflow-x: hidden;
	}

	.for-self.hide {
		left: -240%;
	}

	.for-other {
		right: -240%;
	}

	.box-show {
		min-height: 325px;
	}

	.for-before-arrow,
	.for-after-arrow {
		top: auto;
		bottom: -5px;
		transform: none;
	}

	#tab-choose {
		margin-top: 15px;
	}
}

@media (min-width: 1025px) {
	.box-pagi.for-forum,
	.for-btn-jadwal {
		display: none;
	}

	.c-items input + label {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (min-width: 768px) {
	.post-mobile {
		display: none;
	}

	.logout-mobile,
	.close-menu {
		display: none;
	}

	#sd-mobile,
	#mobile-p,
	#for-m,
	.data-mobile,
	.close-fv,
	.info-dokter {
		display: none;
	}

	.j-center {
		justify-content: center;
	}

	.close-popup {
		position: absolute;
		top: 15px;
		right: 15px;
	}

	.close-popup .btn {
		font-size: 18px;
	}

	.ht-filter,
	.r-filter {
		cursor: auto !important;
	}

	#di,
	.add-schedule {
		padding: 24px;
	}
}

@media (max-width: 450px) {
	.janji-item {
		width: 100% !important;
		text-align: center;
	}

	.box-waktu {
		width: auto !important;
	}
}

@media (max-width: 400px) {
	.dokter-img {
		border-radius: 80px;
		width: 70px;
		height: 70px;
	}

	.dokter-img img {
		object-fit: cover;
		object-position: center;
		height: 70px;
	}
}

@media (min-width: 1921px) {
	.footer.sticky {
		position: fixed;
		bottom: 0;
		top: auto;
		width: 100%;
	}
}
