.btn-toggle-switch {
	margin: 0 4rem;
	padding: 0;
	position: relative;
	border: none;
	height: 1.5rem;
	width: 3rem;
	border-radius: 1.5rem;
	color: #6b7381;
	background: #bdc1c8;
}
.btn-toggle-switch:focus,
.btn-toggle-switch.focus,
.btn-toggle-switch:focus.active,
.btn-toggle-switch.focus.active {
	outline: none;
}
.btn-toggle-switch:before,
.btn-toggle-switch:after {
	line-height: 1.5rem;
	width: 4rem;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle-switch:before {
	content: "Off";
	left: -4rem;
}
.btn-toggle-switch:after {
	content: "On";
	right: -4rem;
	opacity: 0.5;
}
.btn-toggle-switch > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle-switch.active {
	transition: background-color 0.25s;
}
.btn-toggle-switch.active > .handle {
	left: 1.6875rem;
	transition: left 0.25s;
}
.btn-toggle-switch.active:before {
	opacity: 0.5;
}
.btn-toggle-switch.active:after {
	opacity: 1;
}

.btn-toggle-switch:before,
.btn-toggle-switch:after {
	color: #6b7381;
}
.btn-toggle-switch.active {
	background-color: #29b5a8;
}
.btn-toggle-switch.btn-lg {
	margin: 0 5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 2.5rem;
	width: 5rem;
	border-radius: 2.5rem;
}
.btn-toggle-switch.btn-lg:focus,
.btn-toggle-switch.btn-lg.focus,
.btn-toggle-switch.btn-lg:focus.active,
.btn-toggle-switch.btn-lg.focus.active {
	outline: none;
}
.btn-toggle-switch.btn-lg:before,
.btn-toggle-switch.btn-lg:after {
	line-height: 2.5rem;
	width: 5rem;
	text-align: center;
	font-weight: 600;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle-switch.btn-lg:before {
	content: "Tidak Aktif";
	left: -5rem;
}
.btn-toggle-switch.btn-lg:after {
	content: "Aktif";
	right: -5rem;
	opacity: 0.5;
}
.btn-toggle-switch.btn-lg > .handle {
	position: absolute;
	top: 0.3125rem;
	left: 0.3125rem;
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 1.875rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-lg.active {
	transition: background-color 0.25s;
}
.btn-toggle-switch.btn-lg.active > .handle {
	left: 2.8125rem;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-lg.active:before {
	opacity: 0.5;
}
.btn-toggle-switch.btn-lg.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-lg.btn-sm:before,
.btn-toggle-switch.btn-lg.btn-sm:after {
	line-height: 0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.6875rem;
	width: 3.875rem;
}
.btn-toggle-switch.btn-lg.btn-sm:before {
	text-align: right;
}
.btn-toggle-switch.btn-lg.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle-switch.btn-lg.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle-switch.btn-lg.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-lg.btn-xs:before,
.btn-toggle-switch.btn-lg.btn-xs:after {
	display: none;
}
.btn-toggle-switch.btn-sm {
	margin: 0 0.5rem;
	padding: 0;
	position: relative;
	border: none;
	height: 1.5rem;
	width: 3rem;
	border-radius: 1.5rem;
}
.btn-toggle-switch.btn-sm:focus,
.btn-toggle-switch.btn-sm.focus,
.btn-toggle-switch.btn-sm:focus.active,
.btn-toggle-switch.btn-sm.focus.active {
	outline: none;
}
.btn-toggle-switch.btn-sm:before,
.btn-toggle-switch.btn-sm:after {
	line-height: 1.5rem;
	width: 0.5rem;
	text-align: center;
	font-weight: 600;
	font-size: 0.55rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle-switch.btn-sm:before {
	content: "Off";
	left: -0.5rem;
}
.btn-toggle-switch.btn-sm:after {
	content: "On";
	right: -0.5rem;
	opacity: 0.5;
}
.btn-toggle-switch.btn-sm > .handle {
	position: absolute;
	top: 0.1875rem;
	left: 0.1875rem;
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 1.125rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-sm.active {
	transition: background-color 0.25s;
}
.btn-toggle-switch.btn-sm.active > .handle {
	left: 1.6875rem;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-sm.active:before {
	opacity: 0.5;
}
.btn-toggle-switch.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-sm.btn-sm:before,
.btn-toggle-switch.btn-sm.btn-sm:after {
	line-height: -0.5rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.4125rem;
	width: 2.325rem;
}
.btn-toggle-switch.btn-sm.btn-sm:before {
	text-align: right;
}
.btn-toggle-switch.btn-sm.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle-switch.btn-sm.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle-switch.btn-sm.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-sm.btn-xs:before,
.btn-toggle-switch.btn-sm.btn-xs:after {
	display: none;
}
.btn-toggle-switch.btn-xs {
	margin: 0 0;
	padding: 0;
	position: relative;
	border: none;
	height: 1rem;
	width: 2rem;
	border-radius: 1rem;
}
.btn-toggle-switch.btn-xs:focus,
.btn-toggle-switch.btn-xs.focus,
.btn-toggle-switch.btn-xs:focus.active,
.btn-toggle-switch.btn-xs.focus.active {
	outline: none;
}
.btn-toggle-switch.btn-xs:before,
.btn-toggle-switch.btn-xs:after {
	line-height: 1rem;
	width: 0;
	text-align: center;
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	transition: opacity 0.25s;
}
.btn-toggle-switch.btn-xs:before {
	content: "Off";
	left: 0;
}
.btn-toggle-switch.btn-xs:after {
	content: "On";
	right: 0;
	opacity: 0.5;
}
.btn-toggle-switch.btn-xs > .handle {
	position: absolute;
	top: 0.125rem;
	left: 0.125rem;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.75rem;
	background: #fff;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-xs.active {
	transition: background-color 0.25s;
}
.btn-toggle-switch.btn-xs.active > .handle {
	left: 1.125rem;
	transition: left 0.25s;
}
.btn-toggle-switch.btn-xs.active:before {
	opacity: 0.5;
}
.btn-toggle-switch.btn-xs.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-xs.btn-sm:before,
.btn-toggle-switch.btn-xs.btn-sm:after {
	line-height: -1rem;
	color: #fff;
	letter-spacing: 0.75px;
	left: 0.275rem;
	width: 1.55rem;
}
.btn-toggle-switch.btn-xs.btn-sm:before {
	text-align: right;
}
.btn-toggle-switch.btn-xs.btn-sm:after {
	text-align: left;
	opacity: 0;
}
.btn-toggle-switch.btn-xs.btn-sm.active:before {
	opacity: 0;
}
.btn-toggle-switch.btn-xs.btn-sm.active:after {
	opacity: 1;
}
.btn-toggle-switch.btn-xs.btn-xs:before,
.btn-toggle-switch.btn-xs.btn-xs:after {
	display: none;
}
.btn-toggle-switch.btn-secondary {
	color: #6b7381;
	background: #bdc1c8;
}
.btn-toggle-switch.btn-secondary:before,
.btn-toggle-switch.btn-secondary:after {
	color: #6b7381;
}
.btn-toggle-switch.btn-secondary.active {
	background-color: #ff8300;
}
